<template>
  <div>
    <div>
      <h1>綬草半零落</h1>
      <p class="author">林大雨  2021.03.20</p>
      <div>
        <p>
          我們是綬草<br>
          被「瀕危野生動植物物種國際貿易公約」<br>
          列為稀有植物<br>
          我們愛台灣這塊泥土<br>
          所以每年的清明節前後<br>
          我們會以聖潔的花朵慎終追遠<br>
          所以又叫清明草
        </p>
        <p>
          我們以左旋右旋的曼妙舞姿<br>
          以一日開一朵的紅或白花向上追思<br>
          但也因此而暴露了我們的身世<br>
          我們生於斯長於斯<br>
          且根深柢固<br>
          卻也因我們深根以維生的宿根性<br>
          卻成了我們無以為繼的宿命
        </p>
        <p>
          每年清明時節的雨紛或不紛<br>
          我們都被很不中藥的連根挖起<br>
          然而只要還留有一氣<br>
          我們還是深愛台灣這塊土地<br>
          從不曾離<br>
          也不棄
        </p>
        <p>
          做為台灣最小的原生蘭花<br>
          我們出身卑微<br>
          與台灣同生共死<br>
          如今零落天之涯、地之角<br>
          然而我們無愧於心<br>
          無忝於斯土斯民<br>
          我們<br>
          綬之無愧
        </p>
      </div>
      <div>
        <img src="./img/ladies_tresses.jpg" alt="">
        <p class="ps">攝於台大生農學院農場產品展示中心的屋頂上 圖／林芳騰</p>
      </div>
      <div>
        <p></p>
        <p class="ps">「綬草」花語：銘記於心。</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ladiesTresses',
  components: {},
  metaInfo: {
    title: `綬草半零落｜詩`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '我們是綬草/被「瀕危野生動植物物種國際貿易公約」/列為稀有植物'
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: `綬草半零落｜詩｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: '我們是綬草/被「瀕危野生動植物物種國際貿易公約」/列為稀有植物',
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/ladies_tresses',
        vmid: 'og:url'
      },
    ]
  }
}
</script>