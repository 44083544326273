<template>
  <div>
    <div>
      <h1>飲食媽媽</h1>
      <p class="author">林大雨  2018.06.29</p>
      <div>
        <p>
          洗手作羹湯的妳<br>
          平日不喜加油添醋的為人<br>
          處事也從不加蔥、薑、蒜的調味<br>
          影響所及<br>
          堅持三餐也就少油少鹽少糖<br>
          一生奉行每菜都必須用愛爆香<br>
          即使白菜、清湯亦然<br>
          孩子小時候<br>
          每餐一定當季當地當下現煮的溫暖<br>
          日後稍長<br>
          定食定量的努力加餐飯<br>
          還不時身高體重的論斤計兩
        </p>
        <p>
          及至孩子國、高中階段<br>
          會考、學測、指考的亂了篇章<br>
          只好朝三暮四的變些花樣<br>
          五花八門的設計菜單<br>
          偶而七葷<br>
          有時八素的料理<br>
          多鼓勵少油膩<br>
          總之安頓了年少的七情六欲<br>
          青春後還要追加男女的兩性關係<br>
          即使無法滿意的中規<br>
          至少也要差強人意的中矩	
        </p>
        <p>
          而父母是妳另般視如己出的兒女<br>
          孝心是妳最合父母口味的食材<br>
          除了耿耿於懷的愛<br>
          還要晨昏琅琅上口的定省<br>
          晨起噓寒的問暖老人家的胃口<br>
          午餐細火慢燉豆蛋魚肉外加維生素的問候<br>
          再配上水果蔬菜五榖雜糧少量多餐的溫柔<br>
          一如水、空氣、陽光的維他命呵護<br>
          妳如此的歲月如今的忽已晚<br>
          三日入廚下的妳總算熬過<br>
          也熬成<br>
          一道道美味的一生
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'my_mom_is_chief',
  components: {},
  metaInfo: {
    title: `飲食媽媽｜詩`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '妳如此的歲月如今的忽已晚/三日入廚下的妳總算熬過/也熬成/一道道美味的一生'
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: `飲食媽媽｜詩｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: '妳如此的歲月如今的忽已晚/三日入廚下的妳總算熬過/也熬成/一道道美味的一生',
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/my_mom_is_chief',
        vmid: 'og:url'
      },
    ]
  }
}
</script>