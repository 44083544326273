<template>
  <div>
    <div>
      <h1>不是逢人苦譽君 亦狂亦俠亦溫文---悼芳騰師</h1>
      <p class="author">正德國中 王志銘老師 2021.10.12</p>
      <div>
        <p>芳騰老師在新北市正德國中服務其間，除了在國文教學之外，為提升師生閱讀能力，培養優 良閱讀習慣，營造豐富的閱讀環境，奠定終身學習的基本能力，努力推動閱讀教育不遺餘力 而且成績斐然。謹以下列銘記優良事蹟，追思與永誌 芳騰老師的辛苦付出，正德感謝有您!</p>
        <h3>芳騰老師推動閱讀教育具體成果:</h3>
        <p>1、 策畫孟子社區捐贈該社區圖書室圖書五千餘冊、二十座書架、大型圖書櫃五座。並將二十座書架加裝滑輪改製成行動圖書館。快速擴充圖書設備與圖館功能，提升學生閱讀便利性與圖書內容。在十多年度前館藏圖書匱乏的時代，這批圖書與書架的運用，對學校圖書館及閱讀推動有著莫大的幫忙。</p>
        <p>2、 圖書角佈置：於校園各樓層彈性開放活動空間放置圖書架，供學生下課閱讀，並培養學生隨手、隨時、隨地閱讀的習慣。</p>
        <p>3、 策畫利用大型活動期間辦理募書捐款活動，充實圖書與圖書館設備。策畫利用大型活動辦理募書捐款活動：95 年募得款項共 41,200 元。96 年募得款項共 40,370 元。97 年募得書箱 12000元書款 9487 元。98 年募得款項 120180 元。</p>
        <p>4、利用機會發揮「人飢己飢，人溺己溺」精神，籌畫送「愛」書到災區募書活動。募得二手書808 冊，送「愛」書到八八水災災區學校。</p>
        <p>5、利用課餘時間指導學生參加校外徵文比賽，設計創意教學教案，嘉惠學子。九十五年獲行政院「華山新詩」國中組第一名、台北市文學獎青春組新詩首獎、青年世紀文學獎散文第一、二名及新詩第二名、第一屆龍顏 FUN 書獎第四名及佳作兩名。九十六年獲全國「動物與我」徵文第二名（第一名從缺）、青年世紀文學獎新詩第一名。九十七青年世紀文學獎新詩第一名、 林靖娟文教基金會徵文第一名。九十八年青年世紀文學獎新詩第一名。九十七年獲臺北縣首次舉辦的國中小學「閱讀智多星─閱讀創意教學活動設計徵選」國中組特優。</p>
        <p>6、策畫「閱讀達人」能力檢測，提升學生閱讀能力。</p>
        <p>7、主編 86 年至 95 年正德青年及正德成語手冊、正德古文選、正德古詩詞選。</p>
        <p>8、98 年代表學校參加教育部閱讀推手評選深獲好評。</p>
        <p>9、98 年 7 月 31 日退休後仍至學校圖書館擔任志工，推行閱讀不遺餘力。</p>
      </div>
      <div>
        <img src="./img/school_life/YEZoO-000.jpg" alt="">
        <p class="ps">孟子社區捐贈圖書及書架加裝滑輪改製成行動圖書館</p><br>
        <img src="./img/school_life/YEZoO-001.jpg" alt="">
        <p class="ps">圖書角佈置</p><br>
        <img src="./img/school_life/YEZoO-002.jpg" alt="">
        <p class="ps">閱讀教育推廣---為什麼要閱讀</p><br>
        <img src="./img/school_life/YEZoO-003.jpg" alt="">
        <p class="ps">「閱讀智多星─閱讀創意教學活動設計徵選」國中組特優</p><br>
        <img src="./img/school_life/YEZoO-004.jpg" alt="">
        <p class="ps">「閱讀智多星─閱讀創意教學活動設計徵選」國中組特優</p><br>
        <img src="./img/school_life/YEZoO-005.jpg" alt="">
        <p class="ps">閱讀達人博士學位審查</p><br>
        <img src="./img/school_life/YEZoO-006.jpg" alt="">
        <p class="ps">芳騰老師審查提問</p><br>
        <img src="./img/school_life/YEZoO-007.jpg" alt="">
        <p class="ps">芳騰老師審查講評</p><br>
        <img src="./img/school_life/YEZoO-008.jpg" alt="">
        <p class="ps">校長頒發評審聘書</p><br>
        <img src="./img/school_life/YEZoO-009.jpg" alt="">
        <p class="ps">正德青年</p><br>
        <img src="./img/school_life/YEZoO-010.jpg" alt="">
        <p class="ps">正德成語手冊、正德古文選、正德古詩詞選</p><br>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'come_with_fog',
  components: {},
  metaInfo: {
    title: `不是逢人苦譽君 亦狂亦俠亦溫文---悼芳騰師`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '正德國中 王志銘老師 2021.10.12'
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: `不是逢人苦譽君 亦狂亦俠亦溫文---悼芳騰師｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `正德國中 王志銘老師 2021.10.12`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/school_life',
        vmid: 'og:url'
      },
    ]
  }
}
</script>