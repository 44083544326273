<template>
  <div>
    <div>
      <h1>安后土 佛事流程  2021/10/16</h1>
      <div>
        <p>
          大眾向十方諸佛菩薩行三問訊禮：<br>
          一問訊<br>
          二問訊<br>
          三問訊
        </p>
        <p>
          向林芳騰老師行一問訊禮：<br>
          一問訊
        </p>
        <p>
          南無清涼地菩薩摩訶薩(三唱)<br>
          奉請此地土地龍神慈悲作主(三稱)
        </p>
        <p>
          心經(七遍)<br>
          <img src="./img/heart_sutra/1015_01.png" alt="">
        </p>
        <p>
          往生咒(七遍)<br>
          <img src="./img/heart_sutra/1015_01-1.jpg" alt="">
        </p>
        <p>
          消災吉祥咒(三遍)<br>
          <img src="./img/heart_sutra/1015_02.jpg" alt="">
        </p>
        <p>
          七佛滅罪真言(三遍)<br>
          <img src="./img/heart_sutra/1015_03.jpg" alt="">
        </p>
        <p>
          迴向：<br>
          願生西方淨土中，九品蓮花為父母<br>
          花開見佛悟無生，不退菩薩為伴侶
        </p>
        <p>
          大眾向十方諸佛菩薩行三問訊禮：<br>
          一問訊<br>
          二問訊<br>
          三問訊
        </p>
        <p>
          向林芳騰老師行一問訊禮：<br>
          一問訊
        </p>
        <p>
          ------<br>
          法師交代，至此已儀式圓滿，離開後請不要再回頭看囉，讓爸爸今天好好休息了。<br>
          以後再找個風和日麗的好天氣來看看、聊聊天。<br>
          非常感謝各位至親、摯友今日的前來及祝福。<br>
          非常感謝。
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'heart_sutra',
  components: {

  },
  methods: {},
  metaInfo: {
    title: `花葬安后土流程`,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: '花葬安后土流程'
      },
      {
        property: 'og:title',
        content: `花葬安后土流程`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `花葬安后土流程`,
        vmid: 'og:description'
      },
    ]
  }
}
</script>