<template>
  <div class="header" :class="{ isScrolled: isScrolled }">
    <div
      class="drawer-wrap"
      :class="{ active: isOpen }"
      @click="isOpen = false"
    />
    <div class="mb-nav drawer" :class="{ active: isOpen }">
      <router-link
        :to="{ name: 'home' }"
        class="mb-link"
        :class="{ active: isOpen }"
        @click.native="isOpen = false"
      >
        首頁
      </router-link>
      <!-- <router-link
        :to="{ name: 'certification' }"
        class="mb-link"
        @click.native="isOpen = false"
      >
        {{ $t('certification') }}
      </router-link> -->
      
      <!-- <section class="mb-nav-foot"> -->
        <!-- <div>
          <a href="https://www.facebook.com/landfriendlytea" target="_blank">
            <img src="../assets/facebook.svg" />
          </a>
          <a href="https://lin.ee/JXKmuqc" target="_blank"
            ><img src="../assets/line.svg"
          /></a>
        </div> -->
      <!-- </section> -->
    </div>
    <!-- {{ $('HOME') }} -->
    <router-link style="margin-right: auto" :to="{ name: 'home' }" class="logo">
      <div class="name">大 雨</div>
      <!-- <img v-if="isScrolled" src="../assets/logo.png" alt="" />
      <img v-else src="../assets/logo2.png" alt="" /> -->
    </router-link>
    <div class="flex-div">
      <router-link
        :to="{ name: 'home' }"
        class="link"
        :class="{ active: $route.name === 'home' }"
      >
        首頁
      </router-link>
      <!-- <router-link
        :to="{ name: 'products' }"
        class="link"
        :class="{ active: $route.name === 'products' }"
      >
        PRODUCTS
      </router-link> -->
      <!-- <router-link
        :to="{ name: 'events' }"
        class="link"
        :class="{ active: $route.name === 'events' }"
        >EVENTS
      </router-link>
      -->
      <!-- <router-link
        :to="{ name: 'certification' }"
        class="link"
        :class="{ active: $route.name === 'certification' }"
        >{{ $t('certification') }}
      </router-link> -->
    </div>
    <div class="icon-bg desk-hide">
      <div id="nav-icon" :class="{ open: isOpen }" @click="toggleNav">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'header-front',
  data() {
    return {
      activeIndex: '1',
      isScrolled: false,
      isOpen: false,
      hamburgerMenuActived: true
    }
  },
  mounted() {
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
    setTimeout(() => {
      this.isInit = true
    }, 1000)
  },
  watch: {
    $route(to) {
      if (to.name === 'home') {
        this.handleScroll()
      } else {
        this.isScrolled = true
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
    // programStore.SET_PROGRAM({})
  },
  methods: {
    handleScroll() {
      // const mainCol = document.getElementsByClassName('col-center-wrap')[0]
      // this.isScrolled = window.scrollY > mainCol.offsetTop - 80
      // if (this.$route.name === 'home') {
      //   this.isScrolled = window.scrollY > 20
      // } else {
      //   this.isScrolled = true
      // }
    },
    toggleNav() {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style lang="scss">
@import '../element-variables.scss';

.header {
  display: flex;
  position: fixed;
  height: 80px;
  width: 100%;
  box-sizing: border-box;
  z-index: 3;
  background: #fff;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
  .link {
    margin: auto 20px auto 0;
    outline: none;
    text-decoration: none;
    color: $brown2;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 700;
    padding: 6px 0;
    cursor: pointer;
    border-bottom: 2px solid $gray2;
    transition: 0.4s ease;
    &.active {
      padding: 6px 0;
      border-bottom: 2px solid $brown2;
    }
    &:hover {
      padding: 12px 0;
      border-bottom: 2px solid $brown2;
    }
  }
  .flex-div {
    display: flex;
  }
  @media (max-width: 768px) {
    .flex-div {
      display: none;
    }
    .link {
      margin: auto 15px auto 0;
      &:hover {
        padding: 6px 0;
        border-bottom: 2px solid $gray2;
      }
      &.active {
        padding: 6px 0;
        border-bottom: 2px solid $brown2;
      }
    }
  }

  #nav-icon {
    width: 20px;
    height: 15px;
    position: relative;
    margin: 12px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  #nav-icon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #000;
    border-radius: 3px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  #nav-icon span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #nav-icon span:nth-child(2) {
    top: 6px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #nav-icon span:nth-child(3) {
    top: 12px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #nav-icon.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -1px;
    left: 3px;
  }

  #nav-icon.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }

  #nav-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 13px;
    left: 3px;
  }

  .icon-bg {
    position: relative;
    z-index: 2;
    margin: auto 20px;
    width: 40px;
    height: 40px;
    background: $gray1;
    border-radius: 50%;
    &:hover {
      background: $gray2;
    }
    &:active {
      background: $gray3;
    }
  }

  .mb-nav {
    .mb-link {
      display: block;
      height: 40px;
      margin: 10px;
      line-height: 20px;
      text-decoration: none;
      padding: 10px;
      box-sizing: border-box;
      background: $gray1;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;
      &:hover {
        background: $gray2;
      }
      &:active,
      &.active {
        background: $gray3;
      }
    }
  }
}
.logo {
  margin: auto auto auto 20px;
  text-decoration: none;
  height: 100%;
  display: flex;
  .name {
    margin: auto;
    width: 156px;
    font-family: 'Noto Serif TC', serif;
    font-weight: 500;
    padding: 10px;
    font-size: 40px;
    text-decoration: none;
    @media (min-width: 1440px) {
      margin-left: calc(20px + (100vw - 1000px)/2);
    }
    @media (max-width: 1439px) {
      margin-left: calc(20px + (100vw - 680px)/2);
    }
    @media (max-width: 767px) {
      margin-left: 10px;
    }
  }
}
</style>
<style lang="sass" scoped>
@import '../element-variables.scss';

.drawer-wrap
  display: none
  position: fixed
  top: 0
  height: 100vh
  width: 100%
  background-color: rgba(0, 0, 0, 0.3)
  z-index: 0
  height: initial
  bottom: 0
  &.active
    display: block
.drawer
  display: none
  position: fixed
  height: initial
  top: 0
  bottom: 0
  right: 0
  padding: 80px 8px 0 8px
  box-sizing: border-box
  background: #fff
  width: 260px
  transform: translate3d(100%, 0, 0)
  transition: all 0.5s
  z-index: 1
  overflow: hidden
  @media (max-width: 768px)
    display: block
  &.active
    transform: translate3d(0, 0, 0)
    box-shadow: 0 30px 20px 0 rgba(0, 0, 0, 0.1)

.mb-nav
  .mb-nav-foot
    position: fixed
    display: flex
    bottom: 0
    right: 0
    width: 260px
    padding: 30px 0
    background: $red
    font-size: 12px
    div
      >div, .mail
        width: 140px
        color: #fff
        margin-left: 15px
        line-height: 16px
        text-decoration: none
      img
        background: #fff
        margin-left: 5px
        height: 40px
        width: 40px
</style>
