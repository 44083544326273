<template>
  <div>
    <div>
      <h1>槲生若寄──冬日詩寫槲寄生</h1>
      <p class="author">林芳騰  2019.03.13  人間福報</p>
      <div>
        <p>
          所以洗盡鉛華<br>
          繁華落盡<br>
          徒留一生的身世<br>
          只為等待冬日的你<br>
          走過<br>
          為我寫下卑微的浮生<br>
          若<br>
          寄<br>
        </p>
      </div>
      <div>
        <img src="./img/mistletoe.jpeg" alt="">
        <p class="ps">槲生若寄──冬日詩寫槲寄生 圖／林芳騰</p>
      </div>
      <div>
        <p></p>
        <p class="ps">連結：<a href="https://www.merit-times.com/NewsPage.aspx?unid=545575" target="_blank">【人間福報】林芳騰／槲生若寄──冬日詩寫槲寄生</a></p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'mistletoe',
  components: {},
  metaInfo: {
    title: `槲生若寄──冬日詩寫槲寄生｜詩`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '人間福報｜槲生若寄──冬日詩寫槲寄生'
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: `槲生若寄──冬日詩寫槲寄生｜詩｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `人間福報｜槲生若寄──冬日詩寫槲寄生`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/mistletoe',
        vmid: 'og:url'
      },
    ]
  }
}
</script>