<template>
  <div>
    <div>
      <h1>故人、故居、故事</h1>
      <p class="author">林芳騰  2012.12.05  聯合報  第二屆兩岸交流紀實文學獎</p>
      <div>
        <p>每天從劍潭捷運站開出的第一班小16公車，沿著蜿蜒山徑繞駛永公路245巷34弄，最後來到閻錫山故居門口停下來，除非颱風天公車停駛，否則總會看到張日明拄著拐杖下車，緩步走入六十年的記憶，風雨無阻。</p>
        <p>大多時候是張日明獨自一人上山，自民國93年閻錫山的故居「種能洞」與侍衛居住的紅磚樓被台北市文化局指定為市定古蹟後，偶爾會有好奇的遊客上山，近幾年開放大陸人士來台觀光，很多山西同鄉也特地來訪。最近一次是今年8月14日山西太原的昔日部屬的後裔，帶來太原的花生和汾酒來台上山祭拜。</p>
        <p>沿著小徑，張日明一步一步的循著記憶打開「站崗」六十年的紅磚樓，稍作休息，再整肅儀容後，就像昔日擔任侍衛時的精神抖擻，拄著拐杖、提著鮮花素果，一步一步的步下階梯，一絲不苟的打開「種能洞」鐵門的鑰匙，也打開六十年如一日的一生。</p>
        <p>和閻錫山一樣都是山西人的張日明，十五歲從軍開始，就隨著閻錫山南征北討，38年隨國民政府撤退來台時，他是押解中央銀行國庫黃金來台的少尉，後來擔任閻錫山的侍衛。39年，閻錫山辭去「行政院長」後，張日明即隨著閻錫山搬到陽明山居住，「種能洞」就是當時仿山西窯洞而建的。49年閻錫山逝世，逝世迄今五十年來，張日明每天為老將軍上香、打掃故居及墓園，從不間斷。</p>
        <p>張日明回憶，人稱「山西王」的閻錫山是中國近代史上的英雄，曾是1930年美國《時代》周刊的封面人物，一生叱吒沙場，官場卻不得志。將軍辭世的時候，故居還有二十多名隨從，後來大家為了前程各奔東西，他為了生活不得不於隔年也下山居住。不過他與另一名侍衛井國治每天仍上山為將軍上香，井國治於80年因病過世，只剩他一人一生忠心耿耿的看守墓園。</p>
        <p>然而，記憶猶新，歲月卻不饒人。隨著閻錫山生前親自挑選墳墓上的「中」字的斑駁隳壞，張日明無力修復；去年五棵種在「世界大同」題字旁五、六十年的茶花被盜，也無力重新種回，就任其荒蕪。儘管台北市政府文化局已通過將「閻錫山墓」列為市定古蹟，民國100年5月23日將閻錫山陽明山故居及墓園捐贈給台北市政府，年邁八十的現代守墓人卻已不復當年。</p>
        <p>年邁八十的張日明身體還算硬朗，步履卻已蹣跚，從故居到墓地短短的幾百公尺，他越走越長，越走越慢，就不知道哪天真的走不到了，張日明邊掃落葉邊感嘆的說。然而，落葉就像記憶一樣，掃乾淨了，一回頭，又是落葉滿空山。只是再幾年過後，空山依舊在，落葉也依舊，歷史也應猶在，而屬於張日明個人的記憶，將何在？</p>
        <p style="font-weight: 800;">（本文為第二屆兩岸交流紀實文學獎紀實圖文類最佳圖文獎作品）</p>
      </div>
      <div>
        <img src="./img/old_stories.jpeg" alt="">
        <p class="ps">五十年來，張日明風雨無阻地上陽明山，為老長官閻錫山上香、打掃故居和墓園。</p>
      </div>
      <div>
        <p class="ps">連結：<a href="https://paper.udn.com/udnpaper/PIC0004/228091/web/" target="_blank">聯合報／林芳騰／故人、故居、故事/第二屆兩岸交流紀實文學獎</a></p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'oldStories',
  components: {},
  methods: {},
  metaInfo: {
    title: `故人、故居、故事｜散文`,
    meta: [
      { charset: 'utf-8' },
      { property: 'og:type', content: 'website' },
      {
        vmid: 'description',
        name: 'description',
        content: '聯合報｜第二屆兩岸交流紀實文學獎'
      },
      {
        property: 'og:title',
        content: `故人、故居、故事｜散文｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `聯合報｜第二屆兩岸交流紀實文學獎`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/old_stories',
        vmid: 'og:url'
      },
    ]
  }
}
</script>