<template>
  <div>
    <div>
      <h1>再見大雨老師</h1>
      <p class="author">製作剪輯｜林裕翔、Angela Chang</p>
      <div>
        <div class="videoWrapper">
          <iframe class="video" src="https://www.youtube.com/embed/bRh1wRago-8" title="再見大雨老師" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'life_videos_1',
  components: {},
  metaInfo: {
    title: `再見大雨老師`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '製作剪輯｜林裕翔、Angela Chang'
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: `再見大雨老師`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `製作剪輯｜林裕翔、Angela Chang`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/life_videos_1',
        vmid: 'og:url'
      },
    ]
  }
}
</script>
<style lang="scss">
.videoWrapper{
  position: relative;
  margin-left: 20px;
  padding-bottom: 50px;
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  @media (min-width: 1440px) {
    width: 960px;
    height: calc(960px/560*315);
  }
  @media (max-width: 1439px) {
    width: 640px;
    height: calc(640px/560*315);
  }
  @media (max-width: 768px) {
    width: calc(100% - 40px);
    height: calc((100vw - 40px)/560*315);
  }
}
</style>