
<template>
  <div>
    <div>
      <h1>堅固的回憶</h1>
      <p class="author">林大雨  2018.08.31</p>
      <div>
        <p>今年暑假，趁孩子輔導課結束，帶著他們搭乘普悠瑪號南下台東回外公家。</p>
        <p>第一次搭乘普悠瑪，孩子難掩興奮之情，直說好像坐大型的雲霄飛車，一路快速舒適的早上八點從台北出發，中午就到了台東。</p>
        <p>下午接了老人家到山上民宿共享天倫之樂，祖孫兩人久未見面，看到孫子格外的健談。從下午茶聊到晚餐，孩子還意猶未盡的坐在星空下纏著外公講故事，從當年從彰化搬到台東，從開山墾地到白手起家，一路談到台東的今昔。也許是因為面對的是外孫，比較能放開胸懷的暢所欲言，或許是小孩的窮追猛「問」，有很多的過去，也是我第一次聽他提起。</p>
        <p>其中關於為什麼會取名為「堅固水泥加工廠」的故事，動人的情節好像演電影，雖事隔近五十年，老人家中氣十足的一氣呵成，回憶一如「堅固水泥」，雖歷盡滄桑、歲月斑駁，卻記憶猶新，歷歷在目。</p>
        <p>五十年前，年輕的岳父為了要親手蓋一棟自己的房子，他憑著早年在西部幫一家水泥加工廠記帳，日夜接觸水泥的印象，從卑南溪挑砂石拌水泥，自己設計施工，用板模灌水泥柱、水泥板，真的是胼手胝足，一磚一瓦的蓋了一棟「水泥板屋」。</p>
        <p>隔年民國五十四年六月十八日遇上「黛納」颱風直撲台東，災情慘重，據當年資料報導，房屋全倒五千多間，很多磚牆建造的房子都不敵強風的侵襲倒塌，唯獨他親手打造的「水泥板」房子除了屋頂掉瓦外，文風不動。</p>
        <p>當年，大家都窮，災後重建緩不濟急，尤其鄰近的卑南原住民部落。於是他義務幫他們設計簡易的住家，教導他們從卑南溪找砂石，撿當時斷落的台東吊橋殘餘的鐵條綁鋼筋灌水泥柱，有了樑柱支撐，其餘的牆面、屋頂就地取材，很快的就一棟接一棟的重建，猶如現今的組合屋。</p>
        <p>談到救災，「堅固」的故事才真正開始，那個時代沒有「救災」的名詞，颱風過後又接連幾次的颱風，雖然沒有重大傷害，卻是雪上加霜。重建的需求越來越多，於是他就在家附近的空地上築起臨時的工地，幫忙灌水泥柱，除了水泥需要購買酌收成本之外，砂石都是災民自己挑來，鋼筋則因台東吊橋拆除，就地囤放在橋下，大量的鐵條被陸續的大水沖到溪床到處都是，所以大家也都義務的幫忙撿來工地，充作鋼筋。其中還有一段小插曲，因為工地囤放舊吊橋的鐵條，引起轄區的警員託人傳話要強索保護費，否則送衙門法辦，後來經老丈人曉以「救災」大義，才不致「吃官司」。</p>
        <p>除了鄰近的大南、卑南、利嘉部落，沿海岸線的泰源、東河，南下的太麻里、金峰也都遠道而來求助。等救災告一段後，因委託蓋「水泥板屋」的數量日增，他索性改行做水泥加工，因為鋼筋混凝土的日語發音接近「堅固」，所以原住民奔相走告也都以「堅固」稱之，所以就取名「堅固水泥加工廠」。一方面為了沿用原住民的習慣稱呼，一方面也為了講求「堅固」的信用，以紀念這段的因緣。</p>
        <p>「堅固」真的很堅固，前幾年八八風災，我從台北特地南下協助金鋒部落的救災工作，老人家年過八十還是熱心不減，堅持跟我們上山看看他還可以幫忙些什麼，村子裡被大水沖垮了好幾棟鋼筋水泥的建築，而當時他協助建造的「水泥板屋」，除了後來原地改建之外都還「建」在，健在的還有當年幾位的老朋友，提起當年往事，撫今傷昔，不勝唏噓，談到天地無情，更忍不住的老淚縱橫。談到天地無情，他略帶哽咽的提到民國五十八年的傷心往事。</p>
        <p>那年的中秋節遇上艾爾西颱風過境，大南的魯凱族部落發生火災，因全村均是竹木質材搭建的房舍，造成幾近毀村的慘痛悲劇。而那天早上，他剛好進入部落幫幾戶人家丈量地基，準備改建水泥板的建屋，不料當天晚上就發生慘劇，隔天上山救災，已是無力回天，悲痛逾恆，如喪考妣。後來，只要原住民朋友上門需要幫忙或工作，全部來者不拒，以彌補當年的遺憾，也為多災多難的族群，略盡綿薄之力。</p>
        <p>假期最後一天準備回台北，我特地邀了岳父重返部落，找回「堅固」的回憶，車子進到東興村，在入口處達魯瑪克紀念碑前，岳父用日語讀了碑文，他說：「長老才聽得懂！」語音雖略帶顫抖，卻鏗鏘有力，字字含淚。一路步行進入村子，蹣跚的步伐，時而佇足，時而望向遠山，我知道他強忍著傷痛。</p>
        <p>但我不解的是，既非親也非故，何以老人家會如此的悲不可抑。最後來到以魯凱族崇拜的百步蛇為意象設計建造的達魯瑪克橋，他指著湍流的大南溪說五十二年剛搬到台東，租房子在卑南溪旁較便宜的低漥地，與原住民為鄰，起初對他們猶存戒心，處處防範，那一年遇上颱風，所有的家當一夕全被洪水沖走，又舉目無親，簡直到了窮途末路，一度想搬回位於濁水溪旁的溪州老家。幸好部落裡每餐煮了大鍋飯，把外地人的老丈人一家視為他們族人般的邀一起吃飯，一家大小才免受飢寒交迫之苦。雖是杯水粒粟，還是「歷歷」感恩在心，也許不是大恩大德，卻改變了他的一生。</p>
        <p>回到台北，對原住民向來陌生的我，特地到位於台大圖書館地下一樓的原住民圖書館，查閱卑南和魯凱族的相關文獻資料，始瞭解他們的滄桑，也才知道他們的過往。尤其讀了達魯瑪克部落被迫遷離家園、幾經風災、水災和火災的肆虐，我似乎可以理解也是離鄉背井，也歷經風災、水災的老丈人為何老淚縱橫，不忍和不捨。</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'conerete_memory',
  components: {},
  metaInfo: {
    title: `堅固的回憶｜散文`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '為什麼會取名為「堅固水泥加工廠」的故事，動人的情節好像演電影，雖事隔近五十年，老人家中氣十足的一氣呵成，回憶一如「堅固水泥」，雖歷盡滄桑、歲月斑駁，卻記憶猶新，歷歷在目。'
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: `堅固的回憶｜散文｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: '為什麼會取名為「堅固水泥加工廠」的故事，動人的情節好像演電影，雖事隔近五十年，老人家中氣十足的一氣呵成，回憶一如「堅固水泥」，雖歷盡滄桑、歲月斑駁，卻記憶猶新，歷歷在目。',
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/conerete_memory',
        vmid: 'og:url'
      },
    ]
  }
}
</script>