<template>
  <div>
    <div>
      <h1>重讀父親</h1>
      <p class="author">林大雨  2013.10  第15屆磺溪文學獎｜新詩優選</p>
      <div>
        <p>
          住進安寧病房開始<br>
          你重新歸零<br>
          像沙漏倒數計時<br>
          輕輕細細的悄悄流逝<br>
          像字幕一字一句的消失<br>
          我緊握住流沙<br>
          一頁一頁的重讀你的一生
        </p>
        <p>
          沒有扉頁的平裝本<br>
          印刷也只有黑白<br>
          雙親早逝的你<br>
          自己排版<br>
          篇章有些雜亂<br>
          字體也缺少變化<br>
          但你認真負責的校對<br>
          一生沒有錯字
        </p>
        <p>
          簡單的的幾幅插圖<br>
          卻細緻的筆觸<br>
          也就勾勒了你的為人處世<br>
          媽媽題的序<br>
          目錄是我們兄弟加註的<br>
          頁數不多<br>
          不夠寫你平實走完的故事<br>
          最後留了幾張的空白頁<br>
          等你來生再寫
        </p>
      </div>
      <div>
        <img src="./img/reread_father.jpeg" alt="">
        <p class="ps">第15屆磺溪文學獎</p>
      </div>
      <div>
        <p class="ps">連結：<a href="https://gpi.culture.tw/books/1010202057" target="_blank">第15屆磺溪文學獎得獎作品專輯</a></p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'rereadFather',
  components: {},
  metaInfo: {
    title: `重讀父親｜詩`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '第15屆磺溪文學獎｜新詩優選｜重讀父親'
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: `重讀父親｜詩｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `第15屆磺溪文學獎｜新詩優選｜重讀父親`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/reread_father',
        vmid: 'og:url'
      },
    ]
  }
}
</script>