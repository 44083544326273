<template>
  <div class="card2">
    <div class="card-block">
      <h2>追懷想念｜林芳騰</h2>
      <p>大雨是一個愛山、愛探險、到處尋找秘境美景的人，這次也先我們一步出發遠行了。百般不捨，仍要祝福，希望親愛的您旅途一切安好、順利。</p>
      <div class="flex-block-wrap">
        <div class="flex-block">
          <div class="card" v-for="(event, index) in events" :key="`index${index}`">
            <img class="card-img" :src="event.imgUrl" alt="" />
            <div :class="{isEnd:event.status}"> 
              <h3 class="bold">{{ event.title }}</h3>
              <div class="text" v-if="event.time">時間｜{{event.time}}</div>
              <div class="text" v-if="event.site">地點｜{{event.site}}</div>
              <div class="text" v-if="event.site2">地址｜<a target="_blank" :href="event.siteUrl">{{event.site2}}</a></div>
              <div class="text" v-if="event.info">流程｜</div>
              <div class="text2" v-for="(info, infoIndex) in event.info" :key="`infoIndex${infoIndex}`">{{info}}</div>
              <div class="text3" v-for="(info2, info2Index) in event.info2" :key="`info2Index${info2Index}`">{{info2}}</div>
              <div class="text3"  style="margin-top:20px;" v-for="(info3, info3Index) in event.info3" :key="`info3Index${info3Index}`">{{info3}}</div>
              <div class="text3" style="margin-top:20px;" v-if="event.mail">{{event.mail.info1}}<a class="mail" href="mailto:sophiamag@gmail.com">sophiamag@gmail.com</a> {{event.mail.info2}}</div>
              <a class="btn" v-if="event.paper" :href="event.paper" download="訃聞_林芳騰.jpeg">訃聞下載</a>
              <!-- <router-link 
                  class="btn"
                  :to="{
                    name: 'articles_id',
                    params: {
                      id: 'heart_sutra'
                    }
                  }" 
                  v-if="event.title==='陽明山花葬'"
                >
                安后土 流程
                </router-link> -->
              <iframe
                v-if="event.map"
                :src="event.map"
                width="100%"
                height="300"
                frameborder="0"
                class="map"
                style="border:0;margin-top:20px"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </div>
            <div class="photoby" v-if="event.photoby">影像提供 ｜ {{event.photoby}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from '../../src/assets/chief02.jpg'
import img2 from '../../src/assets/chief03.jpg'
import img3 from '../../src/assets/chief04.jpg'
import img4 from '../../src/assets/chief05.jpg'
import img5 from '../../src/assets/訃聞_林芳騰.jpeg'

export default {
  data() {
    return {
      openImgMask: '',
      events: [
        {
          title: '文稿、照片、影像彙整',
          time: '無限時',
          imgUrl: img4,
          info2: [
            '我們目前正在收集整理各種關於爸爸的紀念，並擬將詩文作品彙整於此，與各位摯友一同追懷想念。',
          ],
          mail: {
            info1: '可經由 Line、手機訊息、FB 提供，或是寄到 ',
            info2: '非常感謝！'
          },
          photoby: 'DK'
        },
        {
          status: 'end',
          title: '陽明山花葬（已結束，感謝各位親人摯友前來祝福）',
          time: '2021/10/16（六）上午 11:00',
          site: '陽明山第一公墓臻善園 F4區',
          site2: '台北市北投區泉源路220號',
          siteUrl: 'https://goo.gl/maps/XP57NsWPgWJtmUCd8',
          imgUrl: img3,
          info3: [
            '一個被陽明山群環繞、花木生長的地方；一個能像以前一樣，一起泡茶聊天的好所在。'
          ],
          photoby: 'Catiefly Wang',
          // map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3611.8183568751106!2d121.52511151500842!3d25.14183118392199!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442af7231fa1187%3A0xbcf7c76df80009ea!2z6Ie65YyX5biC5q6v6JGs566h55CG6JmV77yI6Zm95piO5bGx56ys5LiA5YWs5aKT6Zm95piO5bGx6Ie75ZaE5ZyS77yJ!5e0!3m2!1szh-TW!2stw!4v1633613998451!5m2!1szh-TW!2stw'
        },
        {
          status: 'end',
          title: '追思告別式（已結束，感謝各位親人摯友前來祝福）',
          time: '2021/10/13（三）12:50',
          site: '台北市立第一殯儀館 景行廳',
          site2: '台北市中山區民權東路二段145號',
          siteUrl: 'https://goo.gl/maps/7k4reGXhD2wM25cw5',
          imgUrl: img1,
          info: [
            '13:00 禮請法師誦經開示',
            '14:00 家奠儀式',
            '14:20 播放追思影片',
            '14:30 公奠儀式',
            '15:10 封釘儀式',
            '15:30 發引',
          ],
          info2:[
            '懇辭奠儀'
          ],
          paper: img5,
          photoby: 'Angela Chang',
        },
        {
          status: 'end',
          title: '功德法會（已結束，感謝各位親人摯友前來祝福）',
          time: '2021/10/12（二）13:00-16:00',
          site: '上品蓮佛教禮儀_會館',
          site2: '新北市板橋區長江路一段30號',
          siteUrl: 'https://g.page/shangpinlian?share',
          imgUrl: img2,
          info: [
            '誦彌陀寶懺',
          ],
          info2: [
            '法會全程約3個小時，歡迎一起參與。'
          ],
          info3: [
            '「綬草」花語：銘記於心。  (大雨攝於台大生農學院農場產品展示中心的屋頂上)'
          ],
          photoby: '林大雨(屋頂上的綬草)',
          // map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.1566527369214!2d121.45606581500618!3d25.028757283974898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a9b61255c023%3A0xc5d592305bb72d5d!2z5LiK5ZOB6JOu5L2b5pWZ56au5YSAX-acg-mkqA!5e0!3m2!1szh-TW!2stw!4v1633613921149!5m2!1szh-TW!2stw'
        },
        {
          status: 'end',
          title: '頭七法會（已結束，感謝各位親人摯友前來祝福）',
          time: '2021/10/8（五）14:30-17:00',
          site: '上品蓮佛教禮儀_會館',
          site2: '新北市板橋區長江路一段30號',
          siteUrl: 'https://g.page/shangpinlian?share',
          imgUrl: img2,
          info: [
            '無常經',
            '阿彌陀經',
            '父母恩重難報經',
          ],
          info2: [
            '法會全程約2個半小時，歡迎一起參與。'
          ],
          info3: [
            '「綬草」花語：銘記於心。  (大雨攝於台大生農學院農場產品展示中心的屋頂上)'
          ],
          photoby: '林大雨(屋頂上的綬草)',
        },
      ]
    }
  },
  watch: {
    $route() {
    }
  },
  created() {
  },
  methods: {
    
  }
}
</script>

<style lang="scss" scope>
@import '../element-variables.scss';
.card2 {
  // border-bottom: 30px solid #eeeeee;
  .card-block {
    margin-bottom: 10px;
    &:nth-last-child(2) {
      border-bottom: none;
    }
  }
  h2 {
    padding: 30px 30px 0;
    line-height: 1.5;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0.05em;
  }
  h2+p {
    padding: 5px 30px 0 ;
    line-height: 1.5;
    font-size: 13px;
    color: gray;
  }
  .flex-block-wrap {
    width: 100%;
    overflow-x: auto;
  }
  .flex-block {
    display: flex;
    width: -webkit-fill-available;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    .card {
      display: block;
      position: relative;
      width: 360px;
      min-width: 360px;
      margin: 10px;
      border-radius: 10px;
      &:first-child{
        margin-left: 40px;
      }
      .card-img {
        max-height: 240px;
        width: 360px;
        height: 240px;
        border-radius: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        // border-bottom: 2px solid $gray3;
        // cursor: pointer;
        margin-right: 20px;
      }
      .photoby {
        position: absolute;
        top: 212px;
        right: 0;
        font-size: 8px;
        color: #fff;
        font-weight: 600;
      }
      .map {
        position: relative;
        bottom: 0;
      }
      > div {
        padding: 12px;
        font-size: 16px;
        h3,
        p,
        .text,
        .text2,
        .text3{
          margin: 0;
          padding: 6px;
          line-height: 1.2;
          font-size: 12px;
          border-bottom: none;
        }
        h3 {
          font-size: 16px;
        }
        .text2 {
          position: relative;
          top: -22px;
          margin-left: 36px;
          padding: 2px 6px;
        }
        .text3 {
          padding-top: initial;
          padding-bottom: 3px;
        }
        .btn {
          display: block;
          text-align: center;
          margin-top: 10px;
          // bottom: 35px;
          // right: 12px;
          font-size: 13px;
          text-decoration: none;
          background: darkgray;
          color: #fff;
          padding: 10px;
          border-radius: 8px;
        }
      }
      @media (max-width: 768px) {
        width: 252px;
        min-width: 252px;
        margin: 5px;
        border-radius: 10px;
        &:first-child{
          margin-left: 10px;
        }
        .card-img {
          max-height: 168px;
          width: 252px;
          height: 168px;
        }
        .icon {
          position: absolute;
          top: 122px;
        }
        .photoby {
          top: 140px;
          text-shadow: black 0.1em 0.1em 0.2em;
        }
      }
    }
  }
  .card .isEnd {
    h3, div, a {
      color: #bbbbbb;
    }
  }
}
</style>