<template>
  <div>
    <div>
      <h1>應無所住而生遷徙</h1>
      <p class="author">林大雨  2020.07.26</p>
      <div>
        <p>
          遷徙是一種應無所住的信仰<br>
          一種安身立命的遷徙<br>
          朝聖是一種遷徙<br>
          轉山也是<br>
          轉經也是
        </p>
        <p>
          應無所住是一種信仰的遷徙<br>
          從千難萬劫到娑婆堪忍裡<br>
          從此生到生生世世<br>
          從出生到死<br>
          從無到空
        </p>
        <p>
          凡可遷徙的都是一種的信仰<br>
          信仰應無所住<br>
          也都是一種的遷徙<br>
          遷徙<br>
          是一種信仰的應無所住
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'move',
  components: {},
  metaInfo: {
    title: `應無所住而生遷徙｜詩`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '現代詩｜應無所住而生遷徙'
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: `應無所住而生遷徙｜詩｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `現代詩｜應無所住而生遷徙`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/move',
        vmid: 'og:url'
      },
    ]
  }
}
</script>