<template>
  <div class="home">
    <div class="bg">
      <div class="cover-photo">
        <img
          src="../../src/assets/chief01.jpg"
        />
      </div>
      <div class="cover" @click="scrolldown">
        <div class="name">
          <div>大</div>
          <div>雨</div>
        </div>
        <div class="time">
          <div>1954.07</div>
          <div>2021.10</div>
        </div>
        <div class="val">
          <div>Val.<span>00</span></div>
          <div>創/停刊號</div>
        </div>
        <div class="bottom-title-wrap mb-hide">
          <div class="bottom-title">
            <div class="title">FEATURE</div>
            <div class="contents">
              <div v-for="(role,index) in roles" :key="index">{{role}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-text">影像提供 ｜ <a href="https://www.facebook.com/profile.php?id=1658259401" target="_blank">邱慶耀</a></div>
    </div>
    <div class="bottom-title-wrap desk-hide">
      <div class="bottom-title">
        <div class="title">FEATURE</div>
        <div class="contents">
          <div v-for="(role,index) in roles" :key="index">{{role}}</div>
        </div>
      </div>
    </div>
    <div>
      <CardOfLists id="CardOfLists" />
      <CardOfEvents />
    </div>
  </div>
</template>

<script>
import CardOfEvents from '@/components/cardOfEvents.vue'
import CardOfLists from '@/components/cardOfLists.vue'

export default {
  name: 'home',
  components: {
    CardOfEvents,
    CardOfLists
  },
  data() {
    return {
      roles: [
        '詩人｜林大雨',
        '登山的人｜酋長',
        '丈夫｜林芳騰',
        '父親｜拔～、老爸',
        '林家老三｜芳騰、三弟、三哥',
        '同學｜芳騰兄、芳騰班長',
        '雪霸義務解說員｜酋長',
        '陽明山保育志工｜大雨學長',
        '台大家長志工｜大雨老師',
        '孟子社區｜七樓林老師',
        '正德國中｜林老師、芳騰老師',
        '明德國中｜林芳騰老師',
        '重慶國中｜林芳騰老師',
        '莒光國小｜林芳騰老師',
        '九份國小｜林芳騰老師'
      ]

    }
  },
  watch: {
    $route() {
    }
  },
  created() {
    window.scrollTo(0, 0)
  },
  mounted() {
    window.addEventListener('resize', this.onresize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onresize)
  },
  methods: {
    onresize() {
      // this.isMb = window.innerWidth < 768
    },
    goto(name) {
      this.$router.push({ name })
    },
    scrolldown() {
      const el = document.getElementById('CardOfLists')
      // if (el instanceof HTMLElement) {
      let y = el.offsetTop
      window.scroll({
        top: y,
        behavior: 'smooth'
      })
    }
  }
}
</script>
<style lang="scss" scope>
@import '../element-variables.scss';
.bg {
  position: relative;
  max-height: 796px;
  overflow: hidden;
  width: 100%;
  box-shadow: 0 -10px 20px 0 rgba(0,0,0,.1);
  .cover-photo {
    img {
      min-height: 796px;
      max-width: 100vw;
      border-left: none;
      box-sizing: border-box;
    }
  }
  .cover {
    position: absolute;
    width: 300px;
    top: 0;
    right: 20px;
    background: #fff;
    padding: 20px;
    box-shadow: 0 -10px 20px 0 rgba(0,0,0,.1);
    cursor: pointer;
    .name div, .time div, .val div, .val div span{
      text-align: center;
      font-family: 'Noto Serif TC', serif;
      font-weight: 500;
    }
    .name {
      padding: 10px;
      div {
        padding: 10px;
        font-size: 40px;
      }
    }
    .time {
      padding: 10px;
      div {
        padding: 5px;
        font-size: 16px;
        font-weight: 800;
      }
    }
    .val {
      padding: 10px;
      div {
        padding: 5px;
        font-size: 12px;
        font-weight: 800;
      } 
      div span {
        text-align: center;
        font-size: 20px;
        font-weight: 800;
      }
    }
    .bottom-title-wrap{
      display: flex;
      .bottom-title {
        margin: auto;
        margin-top: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 14px;
        border-top: 1px solid #2a2a2a;
        line-height: 1.5;
        width: 170px;
        padding: 12px 10px;
        .title{
          color: #2a2a2a;
          font-size: 16px;
          font-weight: 800;
          padding-bottom: 12px;
        }
        .contents {
          div {
            font-size: 12px;
            color: #2a2a2a;
            padding: 3px 0;
          }
        }
      }
    }
  }
  .bottom-text {
    position: absolute;
    left: 30px;
    bottom: 30px;
    color: #fff;
    a {
      color: #fff;
    }
  }
  @media (min-width: 1670px) {
    .cover-photo {
      img {
        min-height: 796px;
        width: 100vw;
      }
    }
  }
  @media (max-width: 1200px) {
    .cover-photo {
      img {
        height: 100vh;
        min-height: none;
        max-width: none;
        border-left: none;
        box-sizing: border-box;
      }
    }
  }
  @media (max-width: 768px) {
    max-height: 100vh;
    .cover-photo {
      img {
        min-height: 100vh;
      }
    }
    .cover {
      top: 20px;
      width: 150px;
      box-shadow: 0 -10px 20px 0 rgba(0,0,0,.1);
      .name {
        div {
          padding: 8px;
          font-size: 30px;
        }
      }
      .time {
        padding: 6px;
        div {
          padding: 4px;
          font-size: 12px;
        }
      }
      .val {
        padding: 6px;
      }
    }
    .bottom-text {
      font-size: 10px;
      left: 10px;
      bottom: 10px;
      a {
        font-size: 10px;
      }
    }
  }
}
@media (max-width: 768px) {
  .bottom-title-wrap.desk-hide{
    display: block !important;
    padding-top: 20px;
    padding-bottom: 60px;
    margin-top: 40px;
    border-bottom: 30px solid #eeeeee;
    .bottom-title {
      margin: auto;
      margin-top: 20px;
      margin-bottom: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 14px;
      border-top: 1px solid #2a2a2a;
      border-bottom: 1px solid #2a2a2a;
      line-height: 1.5;
      width: 170px;
      padding: 12px 10px;
      .title{
        color: #2a2a2a;
        font-size: 16px;
        font-weight: 800;
        padding-bottom: 12px;
      }
      .contents {
        div {
          font-size: 12px;
          color: #2a2a2a;
          padding: 3px 0;
        }
      }
    }
  }
}
</style>