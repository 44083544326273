<template>
  <div>
    <div>
      <h1>夏日參蟬</h1>
      <p class="author">林大雨  2019.06.12  </p>
      <div>
        <p>
          夏日的黃昏裡我坐在樹下打禪<br>
          蟬從我妄念的心爬出地面<br>
          爬上我雙盤盤錯的肉身<br>
          而那是此身長恨<br>
          長年非我有的皮囊
        </p>
        <p>
          我一呼一吸的亦步亦趨<br>
          從不仰人鼻息的我動了念<br>
          跟著蟬的調息<br>
          尋找羽化的契機<br>
          妄想也金蟬<br>
          脫殼
        </p>
        <p>
          終究<br>
          還是徒然
        </p>
        <p>
          努力的一生參禪<br>
          才在夏日午後的參蟬發現<br>
          禪可以是蟬<br>
          蟬不可以是禪
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'cicada_in_summer',
  components: {},
  metaInfo: {
    title: `夏日參蟬｜詩`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '夏日參蟬'
      },
      { property: 'og:type', content: 'article' },
      {
        property: 'og:title',
        content: `夏日參蟬｜詩｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `夏日參蟬`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/cicada_in_summer',
        vmid: 'og:url'
      },
    ]
  }
}
</script>