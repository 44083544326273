<template>
  <div>
    <div>
      <h1>一座不斷蓄積能量，也不斷釋放能量的火山。</h1>
      <p class="author">林芳騰  2015.04.12  臺北城市精神</p>
      <div>
        <p>這座曾經是湖的城市，位於火山的邊緣，周遭又有多條斷層帶穿越，如同她成長的身世一樣，不斷地受到擠壓、陷落、沖積、抬升。經歷殖民、走過二二八傷痛；歷經動盪、走過政局分裂的不安。像火山一樣的不斷蓄積能量，也不斷的釋放能量，成就了今日的國際都市。</p>
        <p>緊臨臺北的陽明山國家公園，是世界唯一距離都會區不到二十公里的火山型國家公園，因火山活動造成了本區獨特的地質、地形景觀。區內的大、小油坑、馬槽、大磺嘴等地強烈的噴氣孔活動，如同這座城市充滿了熱力和活力，熱情與友善。</p>
        <p>講到臺北市，就會想到陽明山；講到陽明山，就會想到以火山地形聞名的台北市第一高峰——七星山。</p>
      </div>
      <div>
        <img src="./img/volcano.jpeg" alt="">
        <p class="ps">一座不斷蓄積能量，也不斷釋放能量的火山。</p>
      </div>
      <div>
        <p></p>
        <p class="ps">連結：<a href="http://spirit.taipeitravel.net/work.aspx?id=8d23f9f93652ad6" target="_blank">臺北城市精神／林芳騰／一座不斷蓄積能量，也不斷釋放能量的火山。</a></p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'volcano',
  components: {

  },
  methods: {},
  metaInfo: {
    title: `一座不斷蓄積能量，也不斷釋放能量的火山｜小品文`,
    meta: [
      { charset: 'utf-8' },
      { property: 'og:type', content: 'website' },
      {
        vmid: 'description',
        name: 'description',
        content: '臺北城市精神｜這座曾經是湖的城市，位於火山的邊緣，周遭又有多條斷層帶穿越，如同她成長的身世一樣，不斷地受到擠壓、陷落、沖積、抬升。經歷殖民、走過二二八傷痛；歷經動盪、走過政局分裂的不安。像火山一樣的不斷蓄積能量，也不斷的釋放能量，成就了今日的國際都市。'
      },
      {
        property: 'og:title',
        content: `一座不斷蓄積能量，也不斷釋放能量的火山｜小品文｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `臺北城市精神｜這座曾經是湖的城市，位於火山的邊緣，周遭又有多條斷層帶穿越，如同她成長的身世一樣，不斷地受到擠壓、陷落、沖積、抬升。經歷殖民、走過二二八傷痛；歷經動盪、走過政局分裂的不安。像火山一樣的不斷蓄積能量，也不斷的釋放能量，成就了今日的國際都市。`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/volcano',
        vmid: 'og:url'
      },
    ]
  }
}
</script>