<template>
  <div>
    <div>
      <h1>年輕的郵戳</h1>
      <p class="author">林大雨  2015.01.26  </p>
      <div>
        <p>
          接連從轉寄的信件中得知你們年輕的消息<br>
          第一次和你們接觸<br>
          卻也是最後一次看到你們的名字<br>
          一切都還來不及認識<br>
          你們就匆匆的蓋上郵戳<br>
          維特式的結束<br>
          輕輕也重重的告辭
        </p>
        <p>
          我們沒有責或不責的意思<br>
          就像擇與不擇都不是你們想要的選擇<br>
          只是<br>
          今天是七月一日<br>
          全球多一秒鐘校時<br>
          地球自轉變慢和你們當然無關<br>
          但是一秒卻可以將你們扭轉
        </p>
        <p>
          就像大學指考今天開始<br>
          幾年前的你們也正在考試<br>
          分秒必爭的振筆直書<br>
          你們從此展開的的未來<br>
          以及從沒有料想過的現在<br>
          如果當年也多一秒或少一秒<br>
          此刻的你們應還在美麗著幸福<br>
          走在校園的行道樹<br>
          哥德的細數青春的腳步
        </p>
        <p>
          而你們真的太年輕了<br>
          年輕得近乎奢侈<br>
          以致於有關你們的故事<br>
          都僅止於年輕<br>
          只能從報章媒體拼湊你們年輕的憧憬<br>
          卻讀不出你們只有年輕的生命
        </p>
        <p>
          你們的名字都年輕都缺乏想像力<br>
          以致最後的申論題也都發揮得不夠詳細<br>
          以致校園的微風輕輕的一吹<br>
          就都空白著最後的一頁
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'postmark',
  components: {},
  metaInfo: {
    title: `年輕的郵戳｜詩`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '年輕的郵戳'
      },
      { property: 'og:type', content: 'article' },
      {
        property: 'og:title',
        content: `年輕的郵戳｜詩｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `年輕的郵戳`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/postmark',
        vmid: 'og:url'
      },
    ]
  }
}
</script>