<template>
  <div class="">
    <div>
      <h1>生命的走廊</h1>
      <p class="author">林芳騰  2016.07.31  自由副刊</p>
      <div>
        <p>今年1月24日，大學學測創下史上最冷的記錄。</p>
        <p>一整天細雨綿綿，我只好躲在考場的走廊避雨，卻意外發現走廊上到處是蚯蚓屍體，屍橫遍野，有些還在掙扎，有些還陸續奮力爬上階梯，前仆後繼地如一場殊死戰，求生的意志絕不遑多讓場內求勝的考生。</p>
        <p>「可能是連續下雨後，雨水將土壤孔隙堵住，土壤中氧氣降低，因此被迫必須爬出地表吧！」我猜想。只是牠們又如何知道走廊可以躲雨而爬了上來，卻又不知道會被躲雨的人踩死而避開。</p>
        <p>這是一所位於台北市中心的學校，周遭大樓林立，加上學校百年的建築，蚯蚓生存的空間像是多寶格，一格格支離破碎地被收藏，出不去，也進不來，因此可以想見這些蚯蚓是原生種，絕非是外來遷徙。不幸的是牠們沒有隨著都市的發展，而演化成一種比如在水裡可以呼吸的生存機制。</p>
        <p>當台北還是湖，這些蚯蚓住在岸邊與水無爭。而隨著湖水的退去，牠們也跟著最早的台北市民移往市區，卻被水泥叢林困住，每遇下雨，每一窪雨水就成了縮小版的台北湖。唯一的逃亡路線就只剩車道、人行道、走廊。</p>
        <p>這都市進步得太快了，而蚯蚓演化得太慢，跟不上！</p>
        <p>而跟不上的何只是蚯蚓，有的甚至沒跟上！像梅花鹿就沒跟上，消失在台北盆地；像凱達格蘭族也沒跟上，退出台北版圖，徒留凱達格蘭大道供後人憑弔。</p>
        <p>難道不能為牠們設置「生態廊道」嗎？至少樹立一塊「蚯蚓穿越區」，提醒學生小心「足下」。</p>
        <p>「沒有人向校方提出建議嗎？」考完試後，我想問也在考場這所學校就讀的兒子。卻看到一群群考生鑽出考場，淋著雨穿過車道衝上走廊躲雨，像是一條條鑽出地面的蚯蚓！一時，想問的話鯁住在喉嚨裡。</p>
      </div>

      <div>
        <p></p>
        <p class="ps">連結：<a href="https://ent.ltn.com.tw/news/paper/1016617" target="_blank">【自由副刊】林芳騰／生命的走廊</a></p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'corridorOfLife',
  components: {},
  metaInfo: {
    title: `生命的走廊｜散文`,
    meta: [
      { charset: 'utf-8' },
      { property: 'og:type', content: 'website' },
      {
        vmid: 'description',
        name: 'description',
        content: '自由副刊｜今年1月24日，大學學測創下史上最冷的記錄。'
      },
      {
        property: 'og:title',
        content: `生命的走廊｜散文｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `自由副刊｜今年1月24日，大學學測創下史上最冷的記錄。`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/corridor_of_life',
        vmid: 'og:url'
      },
    ]
  }
}
</script>