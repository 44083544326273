<template>
  <div>
    <div>
      <h1>呼吸海洋的高雄</h1>
      <p class="author">林大雨  2014.08.08  </p>
      <div>
        <p>
          高雄是島上最海洋的生物<br>
          台灣最海洋的城市<br>
          鯨魚一樣的台灣<br>
          因為胎生要母乳餵養<br>
          而高雄是台灣最母親的乳房<br>
          以最海洋的文化<br>
          哺育南台灣的土地成長
        </p>
        <p>
          高雄是島上最大的共鳴箱<br>
          最大的肺活量<br>
          大提琴般的台灣<br>
          需要海洋般的音響<br>
          而高雄以最海洋的聲音<br>
          演奏<br>
          台灣最大的港口
        </p>
        <p>
          高雄是島上最海洋的心臟<br>
          台灣最大的動脈<br>
          接收海洋的收縮和舒張<br>
          將海洋的夢想<br>
          輸送到南北各地的微血管<br>
          以海洋的溫暖<br>
          溫暖台灣
        </p>
        <p>
          高雄是島上最海洋的子民<br>
          最海洋的胸襟<br>
          以最海洋的包容<br>
          呼吸台灣最海闊的天空
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'breathin_kaohsiung',
  components: {},
  metaInfo: {
    title: `呼吸海洋的高雄｜詩`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '呼吸海洋的高雄'
      },
      { property: 'og:type', content: 'article' },
      {
        property: 'og:title',
        content: `呼吸海洋的高雄｜詩｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `呼吸海洋的高雄`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/breathin_kaohsiung',
        vmid: 'og:url'
      },
    ]
  }
}
</script>