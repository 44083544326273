<template>
  <div>
    <div>
      <h1>山路</h1>
      <p class="author">林芳騰  2015.06.07  自由副刊</p>
      <div>
        <p>清明節過後冒著風雨，攀登磺嘴山，不是英勇，只因四十年前四位台大學生登磺嘴山翠翠谷，有三位不幸發生山難，其中一位是我的同學。</p>
        <p>一路上走在他們罹難當天曾經走過的山徑，一樣的風，一樣的雨，卻是不一樣的命運和結局。</p>
        <p>我忍著悲痛，也忍著四十年的思念；我走了四十年的山，也走過四十年的歲月。</p>
        <p>人生，不可能再一次的四十。</p>
        <p>我在峰頂的三角點上，雙手合十，心裡默默地告訴他們說：陪你們走了四十年，剩下的山路，你們要自己走了。</p>
      </div>
      <div>
        <p></p>
        <p class="ps">連結：<a href="https://ent.ltn.com.tw/news/paper/887126" target="_blank">【自由副刊】林芳騰／山路</a></p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'mountainPath',
  components: {},
  metaInfo: {
    title: `山路｜小品文`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '自由副刊｜山路'
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: `山路｜小品文｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `自由副刊｜山路`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/mountain_path',
        vmid: 'og:url'
      },
    ]
  }
}
</script>