<template>
  <div>
    <div>
      <h1>佛法僧</h1>
      <p class="author">林大雨  2020.07.26</p>
      <div>
        <p>
          每一片葉子的掉落<br>
          佛號<br>
          每一朵花的凋謝<br>
          讖語<br>
          每一顆流星的殞落<br>
          僧人<br>
          每一種生命的輪迴<br>
          水
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'buddha',
  components: {},
  metaInfo: {
    title: `佛法僧｜詩`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '現代詩｜佛法僧'
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: `佛法僧｜詩｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `現代詩｜佛法僧`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/buddha',
        vmid: 'og:url'
      },
    ]
  }
}
</script>