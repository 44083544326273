import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'
import { initializeApp } from 'firebase/app';
import 'firebase/firestore'

const firebaseConfig = {
apiKey: "AIzaSyDVUpZp4PAt_R4QIEZ-sVbdcMi2LUHj4rU",
authDomain: "mountain3952m-d0b7e.firebaseapp.com",
databaseURL: "https://mountain3952m-d0b7e-default-rtdb.firebaseio.com",
projectId: "mountain3952m-d0b7e",
storageBucket: "mountain3952m-d0b7e.appspot.com",
messagingSenderId: "571365975032",
appId: "1:571365975032:web:b372984a0a5fff51422c0d",
measurementId: "G-BYDTNTJPH3"
}

initializeApp(firebaseConfig)

import { getAnalytics } from "firebase/analytics";
// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics();

Vue.config.productionTip = false

Vue.use(VueMeta)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
