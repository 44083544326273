<template>
  <div>
    <div>
      <h1>失智的愛</h1>
      <p class="author">林芳騰  2014.10.01  聯合報｜愛的行動文學獎｜小品文獎</p>
      <div>
        <p>最初發現媽媽罹患失智症，是因她找不到正確的電燈開關。</p>
        <p>
          我遺傳了父親的青光眼，而父親因青光眼開刀失敗，兩眼幾近失明。父親在世的時候，她一定打開所有的室內燈。媽媽以為我和父親一樣看不清楚，所以只要我假日回家探望她，她看到我回來，就會起身開燈，但是她現在連用了幾十年的電燈開關都會按錯，媽媽患了輕度的失智症。
        </p>
        <p>
          而我不但遺傳了父親的青光眼，也遺傳了母親的糖尿病、高血壓等家族病史。有一次在菜園除草因血糖過低而昏倒，自此，媽媽總是很自責的守在菜園旁的大樹下，盯著我工作，直到我拉著她一起回去才十幾步遠的家。
        </p>
        <p>
          還好，她患的還是初期，所以對早年的記憶還瞭若指掌，有幾件關於我小時候的事，更是如數家珍，每看到我就跟我重述一次，可說倒背如流。其中有一件事，她失智前從未提及。
        </p>
        <p>
          那是民國五十幾年的事，一位住在八卦山另一端山腳下的三家村遠房親戚，有一次翻過八卦山到外公家作客，剛好媽媽帶著我回娘家，席間談到大哥久咳不癒，一直治不好，這位親戚說他有一帖藥方可以治癒，當下她也沒有多考慮便答應他帶著我翻過八卦山到三家村拿藥。媽媽說她可能太心急，也就沒有想到我還只是國小學童，可以跟著去，但一個小孩如何獨自再翻山越嶺回來！
        </p>
        <p>那一天，我怎麼走回家的，毫無記憶。倒是隱藏在她深層記憶裡的痛，失智之後才浮現，因為痛在內心深處，深深的徬徨與無助。媽媽說她當時送我出門不久，才意識到危險，她自責糊塗，竟不顧我的安危，讓小小年紀我冒著風險翻越八卦山，不忍也不捨，說著說著竟哭了出來。</p>
        <p>這之前，我從未見過她如此傷心哭泣。</p>
        <p>媽媽雖然慢慢失智，但，我沒有失去媽媽的愛，失智的愛，讓我體會最深層的母愛，最是單純，發自內心深處，即使往事都已不復記憶，卻始終記得「最初的心」。</p>
        <p>或許這就是《華嚴經》說的「不忘初心」吧！</p>
        <p>也或許，媽媽的愛，因為失智，潛藏於生命深處的記憶才逐一被喚醒，好像銘刻在身體的印記。</p>
      </div>
      <div>
        <p></p>
        <p class="ps">連結：<a href="https://joycefairy.pixnet.net/blog/post/32846067-%E6%84%9B%E7%9A%84%E8%A1%8C%E5%8B%95%E6%96%87%E5%AD%B8%E7%8D%8E-%E5%B0%8F%E5%93%81%E6%96%87%E7%8D%8E%EF%BC%8F%E5%A4%B1%E6%99%BA%E7%9A%84%E6%84%9B" target="_blank">聯合報｜愛的行動文學獎｜小品文獎</a></p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'loveOfDementia',
  components: {},
  metaInfo: {
    title: `失智的愛｜散文`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '聯合報｜愛的行動文學獎｜小品文獎'
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: `失智的愛｜散文｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `聯合報｜愛的行動文學獎｜小品文獎`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/love_of_dementia',
        vmid: 'og:url'
      },
    ]
  }
}
</script>
<style lang="scss" scope>
// @import '../element-variables.scss';
// .articles {
//   display: block;
//   padding-top: 80px;
  // @media (max-width: 768px) {
  // }
// }
</style>