<template>
  <div>
    <div>
      <h1>台北市親山健行有賦五絕十首</h1>
      <p class="author">林大雨  2012.11.30</p>
      <div>
        <p>台北盆地，四面皆山也。何忍「放眼皆青山，任頭生白髮。」搭捷運轉公車，悠遊山林，一日來回。或登山、健行；或行吟賦詩、坐詠言志。何山不詩，何詩不樂，何樂而不為也？</p>
        <h3>春天紗帽行</h3>
        <p>
          風雲山自在，鳥獸共徘徊。<br>
          閱盡陰陽面，看清冷熱來。
        </p>
        <h3>夏遊二子坪</h3>
        <p>
          千花看舞蝶，萬木聽騷蟬。<br>
          鳥語高枝樹，蛙鳴淺水邊。
        </p>
        <h3>秋登大屯山</h3>
        <p>
          一水各西東，芒花夕照紅。<br>
          浮雲金色透，日落古今同。
        </p>
        <h3>冬夜觀七星</h3>
        <p>
          此地落繁星，登臨月色青。<br>
          參商先後在，各自獨飄零。
        </p>
        <h3>貓空找茶</h3>
        <p>
          路遠白雲深，千山萬徑尋。<br>
          逢僧山上下，笑問鐵觀音。
        </p>
        <h3>詠面天山</h3>
        <p>
          孤峰重疊嶂，一岫出雲端。<br>
          霧起山河動，心平地自寬。
        </p>
        <h3>涓絲瀑布健行</h3>
        <p>
          獨好空山音，孤行忘我心。<br>
          聞聲知遠近，聽瀑似彈琴。
        </p>
        <h3>擎天崗草原懷古</h3>
        <p>
          大嶺百千軍，擎天叱吒雲。<br>
          昔為征戰地，今是老牛群。
        </p>
        <h3>四獸山縱走</h3>
        <p>
          初聞虎豹聲，逕入也心驚。<br>
          急欲尋獅象，山深且疾行。
        </p>
        <h3>仙跡岩尋幽</h3>
        <p>
          下界紅塵間，仙人留足跡。<br>
          天機遠近聞，世俗爭相惜。
        </p>
      </div>
      <!-- <div>
        <img src="./img/erziping.jpg" alt="">
        <p class="ps">攝於二子坪 圖／林芳騰</p>
      </div> -->
    </div>
  </div>
</template>

<script>

export default {
  name: 'taipeiHiking',
  components: {},
  metaInfo: {
    title: `台北市親山健行有賦五絕十首｜詩`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '古典詩｜台北市親山健行有賦五絕十首'
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: `台北市親山健行有賦五絕十首｜詩｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `古典詩｜台北市親山健行有賦五絕十首`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/taipei_hiking',
        vmid: 'og:url'
      },
    ]
  }
}
</script>