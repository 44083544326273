import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import Articles from '../views/articles.vue'
import ArticlesId from '../views/articles_id.vue'
import FrontStageMain from '../views/FrontStageMain.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'index',
      component: FrontStageMain,
      children: [
        {
          path: '',
          name: 'home',
          component: Home,
        },
        {
          path: '/articles',
          name: 'articles',
          component: Articles,
        },
        {
          path: '/articles/:id',
          name: 'articles_id',
          component: ArticlesId,
        },
      ],
    },
  ],
})
