<template>
  <div>
    <div>
      <h1>大自然是一本書</h1>
      <p class="author">酋長  2011.05.13  推薦序｜行走，儲藏愛：山與親情的編織</p>
      <div>
        <p>因為愛山，教的又是國文，所以教書以來，一直期待坊間能有一本以台灣山林為創作主題的「教科書」，一則可以作為孩子寫作的教材，向大自然學習；二則可以教導學生認識台灣的山林，豐富孩子的自然經驗。「行走，儲藏愛：山與親情的編織」的出版，終於得償夙願。</p>
        <p>認識琬瑜是因擔任雪霸國家公園義務解說員的機緣而相遇，知道琬瑜是因她也喜歡寫作而相知，更因她的膽識和智慧，真誠和才華而相惜。每次看到她的文章發表，就知道她又從山上下來，帶回來山裡的故事。每次讀她的文章，就像是置身大自然，隨著她流暢的文字，適意的書寫，彷彿可以聽到蟲鳴鳥叫，看到行雲流水，身歷其境。</p>
        <p>大自然是一本書，一本沒有文字的圖畫書。琬瑜在玉山薄雪草的自我介紹：文字如畫筆，將孩子、先生和自己，輕輕點入自然圖像，淡淡描入四季山畫。</p>
        <p>「行走，儲藏愛：山與親情的編織」有如一本圖文並茂的動畫書－－我匍匐著前行，停留在幾塊岩石圍成的洞穴，輕輕地靠近了另一尾躲在「避風港」內的成鮭，靜靜地欣賞著。</p>
        <p>也是一本描述親子關係、描寫親子教養的有聲書－－我和小咕嚕共讀著《春神跳舞的森林》，也循著故事情節與插畫的線索，與他一同回憶我們曾經搭火車上阿里山，去森林中拜訪大樹爺爺的旅程。</p>
        <p>可以看到為人父母的滿足－－現在看著自己的小小孩，也愛上蒲公英，會為了發現一株蒲公英而開心得不得了，覺得在他的身上，彷彿看到了自己和阿德的影子。</p>
        <p>也聽到為人父母對孩子的期許－－與孩子們透過血液，共享肢體勞動而心靈豐足的愉悅訊息？而他們也為追尋著血液中曾留下的氣味而來？或者我們彼此的影響，原互為因果呢？</p>
        <p>這本書像稜鏡，透過琬瑜犀利的筆鋒，大自然隨著海拔的攀升、時序的交替呈現各種不同的面貌。也像顯微鏡，藉由自然寫作，引領自己和小孩探索自然，發現生命最原始的美麗力量；也藉由與小孩天真的對話，激盪出生命最純淨的哲理思維。</p>
        <p>－－棕面鶯正在枝頭上費力地振翅高喊著「拎拎－」、「拎拎拎－」。很難想像體型這樣嬌小的鳥兒，竟然可以發出這麼響亮的鳴聲，難怪牠總是一副要用盡全身氣力的模樣。</p>
        <p>是的，琬瑜「總是一副要用盡全身氣力的模樣」寫這本書，就像她全心全力的教養小孩，全心全意的愛護大自然一樣。這是一本讀了會令人覺得幸福，也讓人感動的一本大自然教科書。</p>
        <p></p>
        <p>
          退休國文教師<br>
          雪霸國家公園義務解說員  酋長
        </p>
      </div>
      <div>
        <img src="./img/the_book_of_nature.jpeg" alt="">
        <p class="ps">《行走，儲藏愛：山與親情的編織》 圖／陳理德</p>
      </div>
      <div>
        <p class="ps">連結：<a href="https://mypaper.pchome.com.tw/wymeng/post/1323257301" target="_blank">《行走，儲藏愛：山與親情的編織》｜推薦序</a></p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'theBookOfNature',
  components: {},
  metaInfo: {
    title: `大自然是一本書｜推薦序`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '因為愛山，教的又是國文，所以教書以來，一直期待坊間能有一本以台灣山林為創作主題的「教科書」，一則可以作為孩子寫作的教材，向大自然學習；二則可以教導學生認識台灣的山林，豐富孩子的自然經驗。「行走，儲藏愛：山與親情的編織」的出版，終於得償夙願。'
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: `大自然是一本書｜推薦序｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `因為愛山，教的又是國文，所以教書以來，一直期待坊間能有一本以台灣山林為創作主題的「教科書」，一則可以作為孩子寫作的教材，向大自然學習；二則可以教導學生認識台灣的山林，豐富孩子的自然經驗。「行走，儲藏愛：山與親情的編織」的出版，終於得償夙願。`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/the_book_of_nature',
        vmid: 'og:url'
      },
    ]
  }
}
</script>