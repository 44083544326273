<template>
  <div>
    <div>
      <h1>巡田水</h1>
      <p class="author">林芳騰  2013.12.04  第九屆林榮三文學獎．小品文獎</p>
      <div>
        <p>父親往生二年，二年中時常夢見，最常夢見的是深夜裡跟著父親巡田水。而所以要三更半夜巡田水，因為水是「偷」來的。</p>
        <p>本來是一彎流水架小橋的良田美地，八七水災過後堤防外移，父親賴以為生的水田全被畫入堤防內，一家生計從此隨河川改道，付之東流。父親為了灌溉，買通當時的工兵部隊在堤防下埋設暗管，始得以耕作，因為是暗管，白天搶不到水，只好半夜偷「放」水。</p>
        <p>小時候的記憶幾乎都模糊，唯有巡田水的往事歷歷，童年也好像從此才有了較清晰的畫面。印象裡父親都定鬧鐘吵醒他，他再把我叫醒和他作伴，然後騎腳踏車載著我，沿著沒有鋪柏油的公路到堤防，再走下堤防到圳溝邊，用板子擋水，讓水位升高流入暗管。清晨再一大早把擋水板移開，免得圳溝下游的莊稼漢灌不到水找上門來。</p>
        <p>暗管的進水口裝了鐵絲網，以防止異物流入堵塞，引道口則放置幾根鐵條阻攔較大的物體卡住進水口，所以必須守在旁邊「顧水」，夢裡夢見的畫面多半在這裡出現。</p>
        <p>由於圳溝是取自上游的溪水，所以溪裡的東西也會隨著溪水流入溝渠。大部分是農民除草後丟棄的雜草，偶爾一些樹枝、漂流木，颱風過後，內山出大水時就會有一些較特別的東西流下來，也是記憶裡最「期待」，也最害怕的畫面。</p>
        <p>進水口如果被異物堵住，水位就會上升漫過水圳，必須下水去清除。而這種事都是我下水，父親的解釋是萬一出事，他可以救我，而我救不了他。其實，父親也怕。我年紀小，當然更害怕，但也充滿期待，尤其出大水。</p>
        <p>出大水時，溪裡的魚蝦就會被大水沖進水圳，再被暗管吸入卡在鐵絲網上，較常抓到鯰魚、土鯽魚、三角鮕，較大的是鰻魚、鱔魚。最大的一次是摸到鱸鰻，以為是蛇，那種驚喜和驚恐僅「一條」之隔，至今夢裡還經常被驚醒。當然，摸到蛇是常有的，活的是水蛇，死掉的是毒蛇，還有死貓、死狗、死老鼠，最大的是死豬，最怕的是撈到骨頭。</p>
        <p>後來水田好幾次被洪水淹沒，每次都得重新開墾，所費不貲，最後改種地瓜、玉米等旱作，從此擺脫了巡田水的「惡」夢，但「噩夢」卻伴我一生。有時夢到夜裡跟在父親腳踏車後面，結果跟丟了；有次夢到下水摸魚，卻摸到人；還有一次夢裡出現父親的身影，被困在洪水裡掙扎，而我不會游泳。</p>
        <p>幾年了，我試著看心理醫師幫忙解析夢，醫師認為癥結在於沒有「田水」可巡，教我試著回去巡田水，退到童年的圳溝邊，找出埋在堤防下的暗管；佛教的朋友則勸我放下父親，持咒念經發慈悲心，就可以消災解厄。</p>
        <p>只是我的癥結不在噩夢，我憂心的是不再噩夢，憂心噩夢裡的父親不再出現。</p>
      </div>
      <div>
        <p></p>
        <p class="ps">連結：<a href="https://ent.ltn.com.tw/news/paper/735686" target="_blank">第九屆林榮三文學獎．小品文獎/林芳騰／巡田水</a></p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'fieldInspection',
  components: {},
  metaInfo: {
    title: `巡田水｜小品文`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '第九屆林榮三文學獎｜小品文獎｜巡田水'
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: `巡田水｜小品文｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `第九屆林榮三文學獎｜小品文獎｜巡田水`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/field_inspection',
        vmid: 'og:url'
      },
    ]
  }
}
</script>