<template>
  <div>
    <div>
      <h1>唯讀父親</h1>
      <p class="author">林大雨  2021.08.04</p>
      <div>
        <p>
          每次想您的時候<br>
          也只能從記憶裡搜尋<br>
          還好您臨終時我為您寫了一首詩<br>
          所以只要輕輕的讀一句<br>
          如同咒語<br>
          進入您的帳號讀取
        </p>
        <p>
          每次夢到您的時候<br>
          畫面都只能黑白<br>
          還好您的生平都已轉存圖檔<br>
          如同螢幕保護程式<br>
          因此影像都保留得很完整清楚<br>
          只是往事也只能唯讀
        </p>
        <p>
          往事都已封存<br>
          只能將您讀出而無法再寫入<br>
          還好您一向瘦弱的身軀雖有病痛<br>
          而您的慈悲沒有病毒<br>
          用來儲存我們開機的系統程式<br>
          沒有因為您的當機而消失
        </p>
        <p>
          只是您去的地方沒有網路<br>
          即使我們更新了近況<br>
          也無法新增到您的動態時報<br>
          也只能把想要對您說的話寫下來<br>
          和媽媽一再的叮嚀交代<br>
          上傳雲端
        </p>
        <p>
          只是媽媽今年已高齡殘燭<br>
          已不堪再自君之出矣的無盡無止<br>
          無法再與君長命無絕衰的相知<br>
          於是在追蹤名單中將您刪除<br>
          此後關閉群組<br>
          自此不再無盡追思
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'rereadFather2',
  components: {},
  metaInfo: {
    title: `唯讀父親｜詩`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '每次想您的時候/也只能從記憶裡搜尋/還好您臨終時我為您寫了一首詩/所以只要輕輕的讀一句/如同咒語/進入您的帳號讀取'
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: `唯讀父親｜詩｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: '每次想您的時候/也只能從記憶裡搜尋/還好您臨終時我為您寫了一首詩/所以只要輕輕的讀一句/如同咒語/進入您的帳號讀取',
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/reread_father2',
        vmid: 'og:url'
      },
    ]
  }
}
</script>