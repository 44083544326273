<template>
  <div>
    <div>
      <h1>雪霸紅毛杜鵑</h1>
      <p class="author">林大雨  2019.08.30</p>
      <div>
        <p>
          為我祝福幫我寫詩<br>
          打從雪霸國家公園成立開始<br>
          我即以身相許<br>
          與雪霸死生契闊<br>
          生死相守
        </p>
        <p>
          只是這一次<br>
          恐將是最後一次了<br>
          在最後一條根系<br>
          被雨水沖刷無以維繫之際
        </p>
        <p>
          但請不要為我嘆息<br>
          不要為我哀戚<br>
          我懂你們的心意<br>
          只求你們不要把我給忘記
        </p>
        <p>
          謝謝你們的不離不棄<br>
          幫我留下文字<br>
          留下最後的身姿<br>
          和今生最後也是最美麗的的回憶<br>
          多謝雪霸人<br>
        </p>
        <p>
          註：<br>
          2019雪霸國家公園解說義工雪山教育訓練途中，發現一株紅毛杜鵑，開在哭坡往雪東的步道上，因長期受到雨水的沖刷，最後只剩下一條根系垂吊在崩塌的岩石上。<br>
          在瀕臨生死存亡之際，她用盡全力開滿了花，同行的夥伴們深受感動，紛紛幫她拍照，與她合影，送上最深的祝福。<br>
          我則在一旁用文字記錄，寫下雪東滿山紅的動人身世，與感人的故事。
        </p>
      </div>
      <div>
        <img src="./img/red_hairy_azalea.jpeg" alt="">
        <p class="ps">雪霸紅毛杜鵑 圖／林芳騰</p>
      </div>
      <div>
        <p class="ps">連結：<a href="https://www.merit-times.com/NewsPage.aspx?unid=561119" target="_blank">人間福報 副刊</a></p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'redHairyAzalea',
  components: {},
  metaInfo: {
    title: `雪霸紅毛杜鵑｜詩`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '人間福報｜2019雪霸國家公園解說義工雪山教育訓練途中，發現一株紅毛杜鵑，開在哭坡往雪東的步道上，因長期受到雨水的沖刷，最後只剩下一條根系垂吊在崩塌的岩石上。'
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: `雪霸紅毛杜鵑｜詩｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `人間福報｜2019雪霸國家公園解說義工雪山教育訓練途中，發現一株紅毛杜鵑，開在哭坡往雪東的步道上，因長期受到雨水的沖刷，最後只剩下一條根系垂吊在崩塌的岩石上。`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/red_hairy_azalea',
        vmid: 'og:url'
      },
    ]
  }
}
</script>