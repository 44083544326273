<template>
  <div>
    <div>
      <h1>呼吸台灣</h1>
      <p class="author">林芳騰  2020.06.10  自由副刊</p>
      <div>
        <p>
          每隔一些時候<br>
          我就得上到海拔三千公尺的高山走一趟<br>
          就像鯨魚<br>
          每隔一段時間也必須浮出海面呼吸一樣<br>
          而比起本來是很美麗的呼吸<br>
          卻遭最不美麗殺機的鯨魚<br>
          顯然我的危險是自己找來的<br>
          我根本可以不必像鯨魚一樣地冒險<br>
          或者我可以學其他魚類用鰓呼吸<br>
          然而就像鯨魚生活在海裡卻必須浮出海面呼吸一樣地可笑<br>
          我也用肺呼吸<br>
          但是三千公尺以下的台灣<br>
          我卻呼吸不到我的空氣<br>
          其實<br>
          愈高的山<br>
          空氣反而愈稀薄<br>
          尤其三千海拔的高山<br>
          大氣壓力很低<br>
          往往只剩下一種叫真誠的感動可供喘息<br>
          尤其是在主峰<br>
          有時更可能只殘存一點點的真情可供患難<br>
          而其實我大可和其他人一樣不仁不義地用肺呼吸<br>
          然後呼來喚去地終其一生<br>
          不必困難地呼吸三千公尺海拔的高山<br>
          然而<br>
          或許是因為海拔三千公尺以下的台灣<br>
          曾經也是海洋<br>
          所以我必須浮出海面<br>
          必須隔一段時間浮出高於海平面三千公尺以上的高山<br>
          就像鯨魚無法像魚類用鰓呼吸必須浮出海面一樣<br>
          或者也是<br>
          三千公尺以上的高山才有我的夢想<br>
          我的夢<br>
          不在海拔三千公尺以下的台灣<br>
        </p>
      </div>
      <div>
        <p></p>
        <p class="ps">連結：<a href="https://ent.ltn.com.tw/news/paper/1378588" target="_blank">【自由副刊】林芳騰／呼吸台灣</a></p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'breathin',
  components: {},
  metaInfo: {
    title: `呼吸台灣｜詩`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '自由副刊｜呼吸台灣'
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: `呼吸台灣｜詩｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `自由副刊｜呼吸台灣`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/breathin',
        vmid: 'og:url'
      },
    ]
  }
}
</script>