<template>
  <div>
    <div>
      <h1>教師手冊</h1>
      <p class="author">林芳騰  2016.10.16  自由副刊</p>
      <div>
        <p>
          一支粉筆<br>
          在我匆忙趕課時失手掉<br>
          <span style="display:block;"><span style="color:#fff">在我匆忙趕課時失手掉</span><span>落在講台的地板上</span></span>
          而當我俯身想撿起時<br>
          才發現不知什麼時候開始<br>
          教室的地板已是層層的粉筆灰<br>
          以及斷<br>
          <span style="display:block;"><span style="color:#fff">以及斷</span><span>落的粉筆</span></span>
          教室到處都是
        </p>
        <p></p>
        <p>
          一株種在教室窗台多年的盆栽<br>
          在我嚴厲懲罰學生時不慎被我打<br>
          <span style="display:block;"><span style="color:#fff">在我嚴厲懲罰學生時不慎被我打</span><span>落</span></span>
          掉在教室的窗戶外<br>
          而當我探出窗外找尋時<br>
          才發現窗外到處都是失足墜<br>
          <span style="display:block;"><span style="color:#fff">才發現窗外到處都是失足墜</span><span>落的盆栽</span></span>
          以及各種被棄的花木校園到處都是
        </p>
        <p></p>
        <p>
          一陣風<br>
          在我發考卷時狂風大作<br>
          才春天的綠葉<br>
          紛紛從講桌吹<br>
          <span style="display:block;"><span style="color:#fff">紛紛從講桌吹</span><span>落在教室的座位上</span></span>
          我拾起掉<br>
          <span style="display:block;"><span style="color:#fff">我拾起掉</span><span>落的片片紅葉</span></span>
          以及不及格的考卷<br>
          卻不知什麼時候天氣已入秋
        </p>
        <p></p>
        <p>
          一場雨<br>
          在我批閱學生聯絡簿時細雨霏霏<br>
          讀著隔代教養的阿嬤用注音符號寫的<br>
          ㄒㄧㄝˋ ㄒㄧㄝˋ ㄌㄠˇ ㄕ<br>
          我提筆直書時<br>
          雨勢加大<br>
          一點一滴<br>
          <span style="display:block;"><span style="color:#fff">一點一滴</span><span>落在墨水未乾的字跡上</span></span>
        </p>
        <p></p>
        <p>
          一張老舊的講桌<br>
          在我下課步下講台時不小心撞倒<br>
          教室的桌椅也被撞得像骨牌般地紛紛倒<br>
          <span style="display:block;"><span style="color:#fff">教室的桌椅也被撞得像骨牌般地紛紛倒</span><span>落在地</span></span>
          當我伸手想將他們一一扶起時<br>
          我這才發現班上的課桌椅大都已破損殘缺<br>
          而究竟從什麼時候開始<br>
          班上的課桌椅已開始殘缺破損<br>
          而我<br>
          卻不知道
        </p>
      </div>
      <div>
        <img src="./img/teachers_manuals.jpeg" alt="">
        <p class="ps">圖為正確的詩行票列</p>
      </div>
      <div>
        <p></p>
        <p class="ps">連結：<a href="https://ent.ltn.com.tw/news/paper/1042338" target="_blank">【自由副刊】林芳騰／教師手冊</a></p>
        <p class="ps">感謝李鼎慧整理提供</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'teachersManuals',
  components: {},
  metaInfo: {
    title: `教師手冊｜詩`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '自由副刊｜教師手冊'
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: `教師手冊｜詩｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `自由副刊｜教師手冊`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/teachers_manuals',
        vmid: 'og:url'
      },
    ]
  }
}
</script>