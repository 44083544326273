<template>
  <div>
    <div>
      <h1>再走一段</h1>
      <p class="author">林芳騰  2014.12.01  第十屆林榮三文學獎．小品文獎</p>
      <div>
        <p>外婆家住在偏遠的山上，小時候跟媽媽回外婆家，沿著山谷，要走一段很長的坑溝路，每次走累了想休息，她總是說：再走一段。</p>
        <p>坑溝路不是一般的道路，而是枯水期的河床，每次大雨過後，路就變得不一樣。一路都是大小不一的石頭，在當時買不起鞋穿的年代，對還只是小學的我，真的是舉步維艱，寸步難行。</p>
        <p>但，媽媽走過來了。小時候的媽媽是村子裡唯一讀完日治時期公學校的女生，因為下山到隔壁村子就讀，一趟來回要走四小時，其他同年齡層的女孩大都「半途」折返。</p>
        <p>「再走一段」是媽媽的走路哲學，一生再困難，再遠的路，都因此而走了過來。媽媽經常提起她小時候走路的過去，因為是山上人家，生活清苦，所以每到假日，她必須幫大人挑一擔鳳梨走過八卦山脈到市區販售，走路走到腳痛得忍不住了就哭，肚子餓得走不動了也哭，就是不曾喊累。再累的路，只要有盡頭，她就不怕。</p>
        <p>她一生不怕走路，但，走在路上有時也會怕。二次世界大戰時，為了跑空襲警報，她跑到腳軟；有一次， 我帶她爬玉山，過斷崖時她嚇到腿軟。再長的路，媽媽都走不怕，但，媽媽怕死！小時候，怕離開她媽媽；當媽媽的時候，怕離開她的孩子。</p>
        <p>媽媽真的怕死，走路看到路上爬的蝸牛，她都會小心翼翼地幫蝸牛移到路邊的草叢裡。她提到當年，每當下雨過後，她就提著籃子到後山撿蝸牛回來餵鴨子，因為積水的墓地，尤其塌陷的墳墓裡最多，為了到墓仔埔多撿一些蝸牛，她都背著我去，不是為了壯膽，而是怕被她剁碎餵鴨子的蝸牛從墳墓堆裡爬出來討命，而她丟不下孩子。</p>
        <p>而當年怕死的媽媽，如今年近九十，反而不怕死，去年因膽囊炎開刀，傷口感染產生併發症，加上長年的糖尿病、高血壓，一直希望我們放棄就醫，讓她走。</p>
        <p>一生不怕走路的媽媽，路愈走愈慢，愈走愈吃力，如今出門最怕走路，每走幾步就要停下來喘氣，尤其山路。</p>
        <p>媽媽不怕死，我卻怕她突然離我而去。以前她常對我說：再走一段；現在換我每次扶著她說：再走一段。</p>
        <p>「媽，再走一段！再走一段就可以看到玉山了。」這是當年帶她爬玉山時，我第一次對媽媽說再走一段。</p>
        <p>後來我發現後山的山徑上，雨後放晴時也可以遠眺玉山群峰。回來我和她說後山山路上就可以看到玉山，還可以看到彩虹，她就很高興地讓我扶著她的手上山走路，就像當年她牽著我的手走路上山一樣。</p>
        <p>她走累了，休息一下後，我再扶著她再走一段。只是，每一次都「半路」折返。</p>
        <p>「媽，再走一段！」我心裡默默地祈求。</p>
      </div>
      <div>
        <p></p>
        <p class="ps">連結：<a href="https://ent.ltn.com.tw/news/paper/835125" target="_blank">第十屆林榮三文學獎．小品文獎/林芳騰／再走一段</a></p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'aLittleMore',
  components: {},
  metaInfo: {
    title: `再走一段｜小品文`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '第十屆林榮三文學獎｜小品文獎｜再走一段'
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: `再走一段｜小品文｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `第十屆林榮三文學獎｜小品文獎｜再走一段`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/a_little_more',
        vmid: 'og:url'
      },
    ]
  }
}
</script>