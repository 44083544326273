<template>
  <div class="card3">
    <div class="card-block">
      <h2>作品彙整</h2>
      <p>陸續會將大家提供的內容整理於此，非常感謝。(點擊藍字標題即可閱讀文章)</p>
      <div class="flex-block-wrap">
        <div class="flex-block">
          <div class="card" v-for="(card, index) in cards" :key="index">
            <div>
              <h3 class="bold">{{ card.title }}</h3>
              <div class="text" v-for="(content,index) in card.contents" :key="index">
                <div>{{content.type}}</div>
                <router-link 
                  class="link"
                  :to="{
                    name: 'articles_id',
                    params: {
                      id: content.routename
                    }
                  }" 
                  v-if="content.routename"
                >
                {{content.title}}
                </router-link>
                <div v-else>{{content.title}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      cards: [
        {
          title: '詩',
          contents: [
            {
              type: '古典',
              title: '二子坪',
              routename: 'erziping',
              // 2018.03.21
            },
            {
              type: '古典',
              title: '台北市親山健行有賦五絕十首',
              routename: 'taipei_hiking',
              // 2012.11.30
            },
            {
              type: '古典',
              title: '美農蟬蘊茶',
              routename: 'chan_yun_tea',
              // 2015
            },
            {
              type: '現代',
              title: '重讀父親',
              routename: 'reread_father',
              // 2013.10
            },
            {
              type: '現代',
              title: '呼吸海洋的高雄',
              routename: 'breathin_kaohsiung',
              // 2014/8/8
            },
            {
              type: '現代',
              title: '年輕的郵戳',
              routename: 'postmark',
              // 2015/1/26
            },
            {
              type: '現代',
              title: '教師手冊',
              routename: 'teachers_manuals',
              // 2016.10.16
            },
            {
              type: '現代',
              title: '上上下下山',
              routename: 'up_and_down',
              // 2018.05.23
            },
            {
              type: '現代',
              title: '飲食媽媽',
              routename: 'my_mom_is_chief',
              // 2018.06.29
            },
            {
              type: '現代',
              title: '人生觀景台',
              routename: 'observatory_of_life',
              // 2018.07.06
            },
            {
              type: '現代',
              title: '你來，霧也來了',
              routename: 'come_with_fog',
              // 2018.08.19
            },
            {
              type: '現代',
              title: '槲生若寄──冬日詩寫槲寄生',
              routename: 'mistletoe',
              // 2019.03.13
            },
            {
              type: '現代',
              title: '屋頂上的榕樹',
              // routename: '',
              // 2019/5/31
            },
            {
              type: '現代',
              title: '雪霸志工情',
              // routename: '',
              // 2019/6/2
            },
            {
              type: '現代',
              title: '夏日參蟬',
              routename: 'cicada_in_summer',
              // 2019/6/12
            },
            {
              type: '現代',
              title: '雪霸紅毛杜鵑',
              routename: 'red_hairy_azalea',
              // 2019.08.30
            },
            {
              type: '現代',
              title: '呼吸台灣',
              routename: 'breathin',
              // 2020.06.10
            },
            {
              type: '現代',
              title: '細讀病危的母親',
              // routename: '',
              // 2020/7/25
            },
            {
              type: '現代',
              title: '土地的聲音',
              // routename: '',
              // 2020/7/25
            },
            
            {
              type: '現代',
              title: '應無所住而生遷徙',
              routename: 'move',
              // 2020.07.26
            },
            {
              type: '現代',
              title: '先驅者',
              // routename: '',
              // 2020/7/28
            },
            {
              type: '現代',
              title: '佛法僧',
              routename: 'buddha',
              // 2020.07.26
            },
            {
              type: '現代',
              title: '走讀台灣這些人那些事',
              // routename: '',
              // 2020/8/6
            },
            {
              type: '現代',
              title: '綬草半零落',
              routename: 'ladies_tresses',
              // 2021.03.20
            },
            {
              type: '現代',
              title: '唯讀父親',
              routename: 'reread_father2',
              // 2021.08.04
            },
            {
              type: '現代',
              title: '三十年樹一木',
              // routename: '',
              // 2021/9/6
            },
            {
              type: '持續更新中...',
              title: ''
            },
          ]
        },
        {
          title: '散文、小品文、隨筆記事',
          contents: [
            {
              type: '推薦序',
              title: '大自然是一本書',
              routename: 'the_book_of_nature'
            },
            {
              type: '散文',
              title: '故人、故居、故事',
              routename: 'old_stories',
            },
            {
              type: '散文',
              title: '失智的愛',
              routename: 'love_of_dementia'
            },
            {
              type: '散文',
              title: '觀霧觀自在',
              routename: 'guanwu',
            },
            {
              type: '散文',
              title: '生命的走廊',
              routename: 'corridor_of_life',
            },
            {
              type: '散文',
              title: '堅固的回憶',
              routename: 'conerete_memory',
            },
            {
              type: '散文',
              title: '繞山',
              // routename: '',
              // 2020/7/25
            },
            {
              type: '小品文',
              title: '巡田水',
              routename: 'field_inspection',
            },
            {
              type: '小品文',
              title: '再走一段',
              routename: 'a_little_more',
            },
            {
              type: '小品文',
              title: '跑道人生',
              // routename: '',
              // 2019/7/30
            },
            {
              type: '小品文',
              title: '火山',
              routename: 'volcano',
            },
            {
              type: '小品文',
              title: '山路',
              routename: 'mountain_path',
            },
            {
              type: '小品文',
              title: '「水」懺',
              routename: 'water_repentance',
            },
            {
              type: '小品文',
              title: '花逝',
              routename: 'fade_away_flower',
            },
            {
              type: '持續更新中...',
              title: ''
            },
          ]
        },
        {
          title: '身影紀錄',
          contents: [
            {
              type: '影片紀錄',
              title: '再見大雨老師',
              routename: 'life_videos_1'
            },
            {
              type: '影片紀錄',
              title: '觀霧觀自在 雪霸留芳蹤 (憶酋長)',
              routename: 'life_videos_2'
            },
            {
              type: '照片集錦',
              title: '長久懷念',
              // routename: ''
            },
            {
              type: '杏林教育',
              title: '正德國中--推動閱讀教育',
              routename: 'school_life'
            },
            {
              type: '自然生態',
              title: '雪霸國家公園 解說志工 25年',
              // title: '觀霧山椒魚棲地',
              // routename: 'life_in_nature'
            },
            {
              type: '自然生態',
              title: '陽明山國家公園 保育志工',
              // title: '觀霧山椒魚棲地',
              // routename: 'life_in_nature'
            },
            {
              type: '持續更新中...',
              title: ''
            },
          ]
        }
      ]
    }
  },
  watch: {
    $route() {
    }
  },
  created() {
  },
  methods: {
    
  }
}
</script>

<style lang="scss">
@import '../element-variables.scss';
.card3 {
  border-bottom: 30px solid #eeeeee;
  .card-block {
    margin-bottom: 10px;
    &:nth-last-child(2) {
      border-bottom: none;
    }
  }
  h2 {
    padding: 30px 30px 0;
    line-height: 1.5;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0.05em;
  }
  h2+p {
    padding: 5px 30px 0 ;
    line-height: 1.5;
    font-size: 13px;
    color: gray;
  }
  .flex-block-wrap {
    width: 100%;
    overflow-x: auto;
  }
  .flex-block {
    display: flex;
    width: -webkit-fill-available;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    .card {
      display: block;
      position: relative;
      width: 360px;
      min-width: 360px;
      margin: 10px;
      border-radius: 10px;
      &:first-child{
        margin-left: 40px;
      }
      > div {
        padding: 12px;
        font-size: 16px;
        h3,
        p {
          margin: 0;
          padding: 6px;
          line-height: 1.2;
          font-size: 12px;
          border-bottom: none;
        }
        .text {
          margin: 0;
          padding: 6px;
          border-bottom: none;
          display: flex;
          >div, >a {
            line-height: 1.2;
            font-size: 12px;
          }
          :first-child { 
            flex-grow: 0;
            flex-shrink: 0;
            border-right: 1px solid #222222;
            padding-right: 5px;
            margin-right: 5px;
          }
        }
        h3 {
          font-size: 16px;
        }
        .link {
          text-decoration: none;
          color: #3970e6;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      @media (max-width: 768px) {
        width: 252px;
        min-width: 252px;
        margin: 5px;
        border-radius: 10px;
        &:first-child{
          margin-left: 10px;
        }
      }
      @media (max-width: 360px) {
        width: 212px;
        min-width: 212px;
      }
    }
  }
}
</style>