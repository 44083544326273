<template>
  <div>
    <div>
      <h1>人生觀景台──兼問台灣高度</h1>
      <p class="author">林大雨  2018.07.06</p>
      <div>
        <p>
          找一座山標示台灣高度<br>
          登一座山宣示台灣版圖<br>
          然而標高多少才是台灣制高點？<br>
          挑一座山挑戰你的極限<br>
          選一座山選定你的志願<br>
          而究竟海拔多高才不會有風險？
        </p>
        <p>
          循著漫漫人生一路攀升<br>
          有些路還勉強草盛豆苗稀的辨識<br>
          有的年久失修<br>
          一路荒蕪<br>
          有一些路前人種樹<br>
          有些已長成合抱之木<br>
          有的找不到陽光<br>
          枯倒在路旁<br>
          有的長了蕨類<br>
          或著生<br>
          或寄生在一棵棵後人乘涼的樹上
        </p>
        <p>
          爬一座山展現氣概<br>
          攀一座山展望未來<br>
          然而多少標高才一覽眾山小？<br>
          海拔多高才能自拔？<br>
          遠觀濁水溪的濁<br>
          起因於最上游支流<br>
          不斷的向中央山脈向源侵蝕<br>
          近看淡水河最上游的不擇細流<br>
          乃源自大霸尖山的砂岩<br>
          一滴滴的從岩縫滴落
        </p>
        <p>
          行到水窮<br>
          坐看一座山<br>
          台灣才是心靈的深處<br>
          不必寬廣視野<br>
          也無須華美<br>
          凡可以坐看雲起<br>
          可吞雲<br>
          也可吐霧<br>
          就是終其一生忙忙碌碌的高度
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'observatoryOfLife',
  components: {},
  metaInfo: {
    title: `人生觀景台──兼問台灣高度｜詩`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '找一座山標示台灣高度/登一座山宣示台灣版圖/然而標高多少才是台灣制高點？/挑一座山挑戰你的極限/選一座山選定你的志願/而究竟海拔多高才不會有風險？'
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: `人生觀景台──兼問台灣高度｜詩｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: '找一座山標示台灣高度/登一座山宣示台灣版圖/然而標高多少才是台灣制高點？/挑一座山挑戰你的極限/選一座山選定你的志願/而究竟海拔多高才不會有風險？',
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/observatory_of_life',
        vmid: 'og:url'
      },
    ]
  }
}
</script>