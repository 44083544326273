<template>
  <div class="frontStageMain">
    <HeaderFrontstage v-if="$route.name !=='home'" />
    <main>
      <router-view />
    </main>
    <footer class="footer">
      <div>
        林芳騰、陳麗華、林雨潸、林雪潸 致謝 © 1954-2021
      </div>
    </footer>
  </div>
</template>

<script>
import HeaderFrontstage from '../components/Header-front'
export default {
  name: 'app',
  components: {
    HeaderFrontstage
  }
}
</script>
<style lang="scss">
.footer {
  background: #eeeeee;
  padding: 20px;
  display: flex;
  div {
    margin: auto;
    font-size: 10px;
  }
}
</style>