<template>
  <div>
    <div>
      <h1>花逝</h1>
      <p class="author">林大雨  2021.07.30</p>
      <div>
        <p>父親因青光眼手術失敗，兩眼近乎失明。</p>
        <p>有次看到父親提著水倒入母親插著乾燥花的花瓶裡，我來不及制止，母親卻一旁搖搖手暗示我不要說，顯然她早就發現。</p>
        <p>母親喜歡花，在八卦山下偏僻的鄉下地方，門口種幾株諸如圓仔花、日日春等還算是常見，像母親會種上幾盆茶花、玫瑰、菊花......等就不多見了。</p>
        <p>每當茶花開了，村子裡的村姑們，就會說媽媽像茶花一樣的「水」，我想她們之中應該沒有人看過「茶花女」，因為除了「水」的形容外，沒聽過其他讚美的形容詞。</p>
        <p>玫瑰花盛開，母親也會剪幾朵送給「厝邊頭尾」，以花會友。</p>
        <p>每到秋天菊花開了，媽媽就把花移至室內，她愛菊，我曾和她提過古人鍾愛菊花的故事。</p>
        <p>從小在八卦山山區長大的母親，沒讀過「茶花女」，也沒看過王禎和的「玫瑰玫瑰我愛妳」，就連紅樓夢裡的林黛玉葬花也沒聽過。</p>
        <p>只是對於父親親手為她種的一棵夜來香，有一天，鄰居對著她說:「今無彩工咧香!」她氣得叫我立刻把夜來香砍除，我留下樹頭捨不得挖掉，她生平第一次對我大聲的斥責說:「人都無佇咧，留彼做啥貨!」</p>
        <p>我清楚媽媽的痛，明白她的苦，也瞭解她一生的委屈，父親是她一生的依賴、仰賴，卻不是她一生仰慕的對象。她想要自主，卻像家裡的電視遙控器一直都在父親的手上操控一樣，她無從選擇。</p>
        <p>父親往生沒多久，有一次她拿著電視遙控器問我怎麼操作，我才知道她的隱忍程度異於常人。</p>
        <p>關於電視，母親卻常提起當年電視機剛出來，父親特地用腳踏車載著她到距離十公里外的草屯，站在店家門口看著黑白電視。回家的路上，父親還特地騎經公路局車站，吃了當年知名的萬壽肉圓才回家。</p>
        <p>偶爾想起，母親還會重提當年往事，彷彿這是她一生的盛事。</p>
        <p>我當然懂母親的心事，也懂她的「花事」，每逢年過節，我都會從台北買花回家，插一盆花放在客廳，早期擺在電視機上，換了液晶螢幕後，改放在她放滿藥罐的架上，再選幾枝供奉神明，有多的就分送左鄰右舍。</p>
        <p>母親一生愛花，但，終其一生也僅只於花花草草的過日子，從沒有屬於自己的一座小小花園。</p>
        <p>如今隨著年華老去，花的記憶，也逐漸的逝去。</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'fadeAwayFlower',
  components: {},
  metaInfo: {
    title: `花逝｜小品文`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '如今隨著年華老去,花的記憶,也逐漸的逝去。'
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: `花逝｜小品文｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: '如今隨著年華老去,花的記憶,也逐漸的逝去。',
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/fade_away_flower',
        vmid: 'og:url'
      },
    ]
  }
}
</script>