<template>
  <div>
    <div>
      <h1>你來，霧也來了</h1>
      <p class="author">林大雨  2018.08.19</p>
      <div>
        <p>
          你剛紅塵滾滾<br>
          一路僕僕風塵的來到觀霧<br>
          還心神未定的不時回首<br>
          崎嶇坎坷的來時路<br>
        </p>
        <p>
          來！<br>
          蹲低你的身子<br>
          讓雲霧洗滌你的驚惶和憂傷<br>
          放下你的身段<br>
          讓雲霧穿過你的眼睛<br>
          撫慰你受傷的心靈<br>
          讓雲、讓霧<br>
          撫平你內心的不平
        </p>
        <p>
          來！<br>
          深呼吸一口氣<br>
          讓芬多精洗去你不快的種種過去<br>
          再森呼吸<br>
          讓負離子進入你的身體<br>
          重新找回迷失的自己
        </p>
        <p>
          來！<br>
          伸出你的雙手<br>
          攔住雲<br>
          攔住你狂野的心和錯過的愛情<br>
          張開你的雙手<br>
          抓住霧<br>
          抓住你的青春和幸福
        </p>
      </div>
      <div>
        <img src="./img/come_with_fog.jpg" alt="">
        <p class="ps">2013 陳克明書法展</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'come_with_fog',
  components: {},
  metaInfo: {
    title: `你來，霧也來了｜詩`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '現代詩｜你來，霧也來了'
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: `你來，霧也來了｜詩｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `現代詩｜你來，霧也來了`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/come_with_fog',
        vmid: 'og:url'
      },
    ]
  }
}
</script>