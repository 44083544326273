<template>
  <div>
    <div>
      <h1>觀霧觀自在</h1>
      <p class="author">林芳騰  2014.11.02  第37屆時報文學獎．小品文獎</p>
      <div>
        <p>爬山爬了四十多年，隨著季節的不同，氣象的不定，以及不同的人，每每都有不同的事、不一樣的物發生，如自然現象看似靜止，卻時刻都在變化。</p>
        <p>今年生日那天在山上巧遇1996年首次在觀霧發現山椒魚的呂光洋教授，和他在觀霧山椒魚生態中心前合照，並約定中秋過後一起溯淡水河最上游支流的塔克金溪，尋找觀霧山椒魚最高海拔的蹤跡。多年奔波來回觀霧的辛苦，似乎也隨著山谷薄暮蒸騰的雲海，慢慢的沉澱，露出了遠方的的台灣海峽，漁火點點。</p>
        <p>2008年雪霸國家公園管理處在觀霧管理站周邊的火燒跡地，規劃運用生態復原方式，進行觀霧山椒魚棲地試驗。於是開啟了我擔任解說志工以來，真正投入生態保育的行列。數不清的日子裡，清晨天剛亮，我從淡水跨越淡水河，一路迎著朝陽，開四小時的車程上山營造「觀霧山椒魚試驗棲地」。這是1996年加入雪霸國家公園解說志工，2011年參加棲地營造工作，固定的時間出發，不變的路線起程，但，多變的是季節的變換，氣候環境的變遷。寒來暑往，然而因颱風、豪大雨帶來的災害也頻繁，多次造成觀霧山椒魚棲地環境的破壞與消失，風災、水災、森林火災、真的多災多難，生存困難。去年七月的蘇力颱風更重創了觀霧，被迫休園，直到今年七月一日才又全面開放。</p>
        <p>每次上山就好像和觀霧山椒魚約會，和大霸尖山談一次戀愛。年輕時，登過三尖五嶽、橫越中央山脈、走過聖稜。曾經從台北南下攀登玉山主峰一日來回而引以為傲，雪季獨攀秀姑巒而自豪；也曾颱風天不聽勸阻，冒險上山而失足、迷路。自以為是的年輕一時，如今重新歸零，重新學習。</p>
        <p>不同以往，如今心有所依，每月上網登記，上山服勤。不再有攻頂的雄心，也沒有征服自己的壯志，做的都是簡單而重複的事。比如剷除復育棲地上的高山芒，培育、種植如山胡椒、霧社櫻、昆蘭樹、大葉石櫟……等原生種苗木；整理棲地附近之水溝，挖取、鋪灑枯枝落葉及腐植質，覆蓋裸露表土以增加潛在植物之種源，恢復生物多樣性，增加山椒魚所需之食物來源，未來還要加入後續的調查和監測工作。《沙郡年記》作者李奧帕德（Aldo Leopold）寫道：「保育是一個人與土地之間的和諧狀態。」我以平和的心守護山林，思考人與自然的和平關係。</p>
        <p>爬山爬了四十多年之後，重新開啟了我另一種的爬山經驗，重新思考，也重新出發。雖然做的都是重複簡單的工作，但隨著季節的不同，氣象的不定，以及不同的人，每每都有不同的事、不一樣的物發生，如自然現象看似靜止，卻時刻都在變化，就如在棲地發現觀霧山椒魚回來了。</p>
        <p>當年爬山路過阿里山慈雲寺，看到牆上題壁詩：「啟窗日日對青山，山色青青不改顏；我問青山何日老，青山問我幾時閒。」當時深受感動，如今則另有一番領悟，「觀自在」就好！</p>
      </div>
      <div>
        <img src="./img/guanwu.jpg" alt="">
        <img src="./img/guanwu2.jpg" alt="">
        <p class="ps">2013 陳克明書法展</p>
      </div>
      <div>
        <p></p>
        <p class="ps">連結：<a href="https://www.chinatimes.com/newspapers/20141102000567-260117?chdtv" target="_blank">中國時報／林芳騰／觀霧觀自在</a></p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'guanwu',
  components: {},
  metaInfo: {
    title: `觀霧觀自在｜散文`,
    meta: [
      { charset: 'utf-8' },
      { property: 'og:type', content: 'website' },
      {
        vmid: 'description',
        name: 'description',
        content: '中國時報｜爬山爬了四十多年，隨著季節的不同，氣象的不定，以及不同的人，每每都有不同的事、不一樣的物發生，如自然現象看似靜止，卻時刻都在變化。'
      },
      {
        property: 'og:title',
        content: `觀霧觀自在｜散文｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `中國時報｜爬山爬了四十多年，隨著季節的不同，氣象的不定，以及不同的人，每每都有不同的事、不一樣的物發生，如自然現象看似靜止，卻時刻都在變化。`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/guanwu',
        vmid: 'og:url'
      },
    ]
  }
}
</script>