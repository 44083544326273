<template>
  <div class="articles">
    <div>
      <poem01 v-if="show('red_hairy_azalea')" />
      <poem02 v-if="show('breathin')" />
      <poem03 v-if="show('mistletoe')" />
      <poem04 v-if="show('teachers_manuals')" />
      <poem05 v-if="show('chan_yun_tea')" />
      <poem06 v-if="show('erziping')" />
      <poem07 v-if="show('reread_father')" />
      <poem08 v-if="show('my_mom_is_chief')" />
      <poem09 v-if="show('reread_father2')" />
      <poem10 v-if="show('observatory_of_life')" />
      <poem11 v-if="show('ladies_tresses')" />
      <poem12 v-if="show('taipei_hiking')" />
      <poem13 v-if="show('up_and_down')" />
      <poem14 v-if="show('come_with_fog')" />
      <poem15 v-if="show('move')" />
      <poem16 v-if="show('buddha')" />
      <poem17 v-if="show('breathin_kaohsiung')" />
      <poem18 v-if="show('postmark')" />
      <poem19 v-if="show('cicada_in_summer')" />
      <article01 v-if="show('love_of_dementia')" />
      <article02 v-if="show('the_book_of_nature')" />
      <article03 v-if="show('corridor_of_life')" />
      <article04 v-if="show('guanwu')" />
      <article05 v-if="show('old_stories')" />
      <article06 v-if="show('volcano')" />
      <article07 v-if="show('mountain_path')" />
      <article08 v-if="show('water_repentance')" />
      <article09 v-if="show('a_little_more')" />
      <article10 v-if="show('field_inspection')" />
      <article11 v-if="show('fade_away_flower')" />
      <article12 v-if="show('conerete_memory')" />
      <event01 v-if="show('school_life')" />
      <event02 v-if="show('heart_sutra')" />
      <event03 v-if="show('life_videos_1')" />
      <event04 v-if="show('life_videos_2')" />
    </div>
  </div>
</template>

<script>
import poem01 from '@/assets/articles/poem/red_hairy_azalea.vue'
import poem02 from '@/assets/articles/poem/breathin.vue'
import poem03 from '@/assets/articles/poem/mistletoe.vue'
import poem04 from '@/assets/articles/poem/teachers_manuals.vue'
import poem05 from '@/assets/articles/poem/chan_yun_tea.vue'
import poem06 from '@/assets/articles/poem/erziping.vue'
import poem07 from '@/assets/articles/poem/reread_father.vue'
import poem08 from '@/assets/articles/poem/my_mom_is_chief.vue'
import poem09 from '@/assets/articles/poem/reread_father2.vue'
import poem10 from '@/assets/articles/poem/observatory_of_life.vue'
import poem11 from '@/assets/articles/poem/ladies_tresses.vue'
import poem12 from '@/assets/articles/poem/taipei_hiking.vue'
import poem13 from '@/assets/articles/poem/up_and_down.vue'
import poem14 from '@/assets/articles/poem/come_with_fog.vue'
import poem15 from '@/assets/articles/poem/move.vue'
import poem16 from '@/assets/articles/poem/buddha.vue'
import poem17 from '@/assets/articles/poem/breathin_kaohsiung.vue'
import poem18 from '@/assets/articles/poem/postmark.vue'
import poem19 from '@/assets/articles/poem/cicada_in_summer.vue'
import article01 from '@/assets/articles/article/love_of_dementia.vue'
import article02 from '@/assets/articles/article/the_book_of_nature.vue'
import article03 from '@/assets/articles/article/corridor_of_life.vue'
import article04 from '@/assets/articles/article/guanwu.vue'
import article05 from '@/assets/articles/article/old_stories.vue'
import article06 from '@/assets/articles/article/volcano.vue'
import article07 from '@/assets/articles/article/mountain_path.vue'
import article08 from '@/assets/articles/article/water_repentance.vue'
import article09 from '@/assets/articles/article/a_little_more.vue'
import article10 from '@/assets/articles/article/field_inspection.vue'
import article11 from '@/assets/articles/article/fade_away_flower.vue'
import article12 from '@/assets/articles/article/conerete_memory.vue'
import event01 from '@/assets/articles/event/school_life.vue'
import event02 from '@/assets/articles/event/heart_sutra.vue'
import event03 from '@/assets/articles/event/life_videos_1.vue'
import event04 from '@/assets/articles/event/life_videos_2.vue'

export default {
  name: 'home',
  components: {
    poem01,
    poem02,
    poem03,
    poem04,
    poem05,
    poem06,
    poem07,
    poem08,
    poem09,
    poem10,
    poem11,
    poem12,
    poem13,
    poem14,
    poem15,
    poem16,
    poem17,
    poem18,
    poem19,
    article01,
    article02,
    article03,
    article04,
    article05,
    article06,
    article07,
    article08,
    article09,
    article10,
    article11,
    article12,
    event01,
    event02,
    event03,
    event04,
  },
  data() {
    return {}
  },
  watch: {
    $route() {
    }
  },
  created() {
    window.scrollTo(0, 0)
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    show(target) {
      return this.$route.params.id === target
    },
    goto(name) {
      this.$router.push({ name })
    }
  }
}
</script>
<style lang="scss" scope>
@import '../element-variables.scss';
.articles {
  display: block;
  padding-top: 120px;
  padding-bottom: 60px;
  display: flex;
  >div{
    margin: auto;
    h1, p, img {
      padding: 20px;
    }
    h3 {
      padding-top: 20px;
      padding-left: 20px;
    }
    p {
      line-height: 2;
    }
    img {
      max-width: 100%;
      box-sizing: border-box;
    }
    // padding: 20px;
  }
  .ps {
    font-size: 12px;
    padding: 0 20px;
  }
  .author {
    color: #aaaaaa;
    padding-top: 0;
  }
  @media (min-width: 1440px) {
    >div{
      width: 1000px;
    }
  }
  @media (max-width: 1439px) {
    >div{
      width: 680px;
    }
  }
  @media (max-width: 767px) {
    >div{
      width: 100%;
    }
  }
}
</style>