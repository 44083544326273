<template>
  <div>
    <div>
      <h1>美農蟬蘊茶</h1>
      <p class="author">林大雨  2015</p>
      <div>
        <p>
          草生小綠葉蟬飛<br>
          仿若浮塵子隱微<br>
          日照卑山侵早起<br>
          月昇高頂每遲歸<br>
          平生憫地祈無毒<br>
          終歲悲田轉有機<br>
          蟬蘊茶香花釀蜜<br>
          美農栽作汗沾衣<br>
        </p>
        <p class="ps">
          洪澤南老師吟唱<br>
          <audio src="./chan_yun_tea.mp3" controls></audio>
        </p>
        <p></p>
        <p class="ps">
          茶葉經古稱「浮塵子」的小綠葉蟬吸吮，造成生長的困境，因此開啟了茶葉的生命密碼逆境成長，醞釀出蜜香，意外發現了特殊的風味。<br>
          一場茶與小綠葉蟬美麗的邂逅，改變了一生慣行農法種植的茶農轉而有機栽培，也從逆境的栽培過程中，體悟生命的逆來順受改變了逆勢人生。<br>
          這是世居卑南溪畔、卑南山下，世代在美農台地種茶的茶農美麗的故事，也是小綠葉蟬蘊藏的故事，我們名之為「美農蟬蘊茶」。
        </p>
        <p></p>
        <h1>Meinong Chan Yun Tea</h1>
        <p class="author">Translated by Charlie Storrar</p>
        <p>
          Among these plants, where the leafhopper flies,<br>
          Where the small green tea jassid hides,<br>
          We rise with the dawn above Beinan Mountain,<br>
          Return again late with the moon over Gaoding.<br>
          Our lives spent in prayer for the good of the land,<br>
          Our fields organic all the year round.<br>
          Chan Yun Tea, with its sweet honey flavor,<br>
          Grown here in Meinong, the fruit of our labor.
        </p>
        <p class="ps">
          When tea leaves are nibbled by the small green leaphopper or tea jassid, a secret defense mechanism is activated in the plant that unexpectedly and organically produces a distinctive honey flavor in the tea.<br>
          This story of the tea and the leafhopper takes place in the tea-growing area of Gaoding, by the banks of the Beinan River under Beinan Mountain. We call the product of this romance Meinong Chan Yun Tea.
        </p>
      </div>
      <div>
        <img src="./img/chan_yun_tea.jpg" alt="">
        <p class="ps"></p>
      </div>
      <div>
        <p></p>
        <p class="ps">連結：<a href="https://chanyuntea.com/zh" target="_blank">蟬蘊茶｜有機、純淨、手採｜自由自在成長的，台東蜜香好茶</a></p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'chanYunTea',
  components: {},
  metaInfo: {
    title: `美農蟬蘊茶｜詩`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '古典詩｜美農蟬蘊茶'
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: `美農蟬蘊茶｜詩｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `古典詩｜美農蟬蘊茶`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/chan_yun_tea',
        vmid: 'og:url'
      },
    ]
  }
}
</script>