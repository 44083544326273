<template>
  <div>
    <div>
      <h1>「水」懺</h1>
      <p class="author">林芳騰  2018.08.09  自由副刊</p>
      <div>
        <p>爬大霸途中，從簡訊中得知母親急性肺炎住院，山上通訊不良，後續訊息無法進一步獲知。三天兩夜的大霸之行，內心的煎熬比背上的行李還重，比來回五十公里的山路還難行。</p>
        <p>下山途中，經大鹿林道東線十四．四公里處的東線瀑布，見瀑布下一塊變質岩，經利如刀刃的水，日夜朝暮，千年萬載的雕塑下，形似一揹負重裝的登山客面水佇立。</p>
        <p>究竟是發生何等的不幸或變故，希望藉此洗滌他無限的傷痛；或登山途中發生重大的意外，想藉著瀑布的沖刷，懺除他內心的愧疚？是失去至親、至愛、或至友？</p>
        <p>究竟是如何的懺悔，又何其的悲願，何以自責至此？</p>
        <p>想起這幾天倉皇於千巖萬豁中，我悲從中來，深深的向他一鞠躬，向瀑布下的行者深深的致敬。</p>
      </div>
      <div>
        <img src="./img/water_repentance.jpeg" alt="">
        <p class="ps">爬大霸途中，從簡訊中得知母親急性肺炎住院，山上通訊不良，後續訊息無法進一步獲知。三天兩夜的大霸之行，內心的煎熬比背上的行李還重，比來回五十公里的山路還難行。圖／林芳騰</p>
      </div>
      <div>
        <p></p>
        <p class="ps">連結：<a href="https://www.merit-times.com/NewsPage.aspx?unid=515383" target="_blank">【自由副刊】林芳騰／「水」懺</a></p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'waterRepentance',
  components: {},
  metaInfo: {
    title: `「水」懺｜小品文`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '自由副刊｜「水」懺'
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: `「水」懺｜小品文｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `自由副刊｜「水」懺`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/water_repentance',
        vmid: 'og:url'
      },
    ]
  }
}
</script>