<template>
  <div>
    <div>
      <h1>二子坪</h1>
      <p class="author">林大雨  2018.03.21</p>
      <div>
        <p>
          千花看舞蝶，萬木聽騷蟬。<br>
          鳥語高枝樹，蛙鳴淺水邊。
        </p>
      </div>
      <div>
        <img src="./img/erziping.jpg" alt="">
        <p class="ps">攝於二子坪 圖／林芳騰</p>
      </div>
      <div>
        <p class="ps">連結：<a href="https://www.ymsnp.gov.tw/main_ch/com_tourmap_m.aspx?id=4&uid=1388&pid=72" target="_blank">陽明山國家公園｜遊憩資訊｜二子坪</a></p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'erziping',
  components: {},
  metaInfo: {
    title: `二子坪｜詩`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '古典詩｜二子坪'
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: `二子坪｜詩｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `古典詩｜二子坪`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/erziping',
        vmid: 'og:url'
      },
    ]
  }
}
</script>