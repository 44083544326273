<template>
  <div class="articles">
    <div>
      <CardOfLists />
    </div>
  </div>
</template>

<script>
// import CardOfEvents from '@/components/cardOfEvents.vue'
import CardOfLists from '@/components/cardOfLists.vue'

export default {
  name: 'home',
  components: {
    CardOfLists
  },
  data() {
    return {}
  },
  watch: {
    $route() {
    }
  },
  created() {
    window.scrollTo(0)
  },
  mounted() {
    window.addEventListener('resize', this.onresize)
    window.scrollTo(0, 0)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onresize)
  },
  methods: {
    onresize() {
      // this.isMb = window.innerWidth < 768
    },
    goto(name) {
      this.$router.push({ name })
    }
  }
}
</script>
<style lang="scss" scope>
@import '../element-variables.scss';
.bg {
  position: relative;
  
  @media (max-width: 768px) {
  }
}
</style>