<template>
  <div>
    <div>
      <h1>上上下下山</h1>
      <p class="author">林大雨  2018.05.23</p>
      <div>
        <p>
          年輕時<br>
          經常爬山爬到一半<br>
          就下山折返<br>
          後來年紀稍長<br>
          反而會下山下到一半<br>
          回頭再奮力爬上山<br>
          如此的上上下下<br>
          人生過了前半段<br>
          如今上到一半<br>
          卻無法決定究竟要不要下山
        </p>
        <p>
          雖是大半人生都半途下山<br>
          但人生大半都還是
        </p>
        <p>
          大半生<br>
          大半人生
        </p>
        <p>
          上山也是下山<br>
          下山是
        </p>
        <p>
          人生上上下下是山<br>
          起起伏伏是山<br>
          高高低低也是山<br>
          上山是下山的起步<br>
          下山是上山的
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'up_and_down',
  components: {},
  metaInfo: {
    title: `上上下下山｜詩`,
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: '現代詩｜上上下下山'
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: `上上下下山｜詩｜林芳騰老師追懷紀念及詩文作品彙整`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: `現代詩｜上上下下山`,
        vmid: 'og:description'
      },
      {
        property: 'og:url',
        content: 'https://mountain3952m.tw/articles/up_and_down',
        vmid: 'og:url'
      },
    ]
  }
}
</script>